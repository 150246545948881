import React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"
import Link from "next/link"
import colors from "@constants/colors"
import { bodyBold, subtext, smallcaps } from "@constants/typography"
import { AddPlus, RemoveMinus, Trash } from "@components/icons"
import SquareIconButton from "@components/buttons/SquareIconButton"
import VerticalSpace from "@components/VerticalSpace"
import { formatCurrency } from "@utils/numbers"
import { getProductUrl } from "@utils/urls"
import { CartItem } from "@redux/types"
import _ from "lodash"

const Container = styled.div`
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  display: flex;
  padding: 32px 0;
`

const Column = styled.div`
  flex-direction: column;
  align-self: stretch;
  display: flex;
`

const ImageLink = styled.a<{ hasLink?: boolean }>`
  align-self: flex-start;
  ${({ hasLink }) => (hasLink ? "cursor: pointer" : "pointer-events: none")};
`

const ImageContainer = styled.div`
  position: relative;
  flex-shrink: 0;
  overflow: hidden;
  width: 68px;
  height: 68px;
  margin-right: 20px;
  background-color: ${colors.divider};

  ${up("laptop")} {
    width: 88px;
    height: 88px;
    margin-right: 24px;
  }
`

const Image = styled.img`
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
`

const Content = styled.div`
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
`

const Name = styled.div`
  ${bodyBold}
`

const Option = styled.div`
  ${subtext}
  color: ${colors.slateDark};
`

const Price = styled.div`
  ${bodyBold}
  margin-left: 20px;
  color: ${colors.primary};

  ${up("laptop")} {
    margin-left: 30px;
  }
`

const TrashButton = styled.button`
  align-items: center;
  align-self: center;
  justify-content: center;
  justify-self: flex-end;
  display: flex;
  width: 44px;
  height: 44px;
  margin-left: 20px;
  padding: 12px;
  margin-top: auto;
  outline: none;
  border: none;
  background: none;

  &:focus,
  &:active {
    outline: none;
  }

  ${up("laptop")} {
    margin-left: 30px;

    &:hover {
      cursor: pointer;
    }
  }
`

const QuantityInputs = styled.div`
  align-items: center;
  justify-content: flex-start;
  display: flex;
  transform: translateX(-10px);

  ${SquareIconButton} {
    padding: 0;
    background-color: transparent;

    &:disabled {
      cursor: not-allowed;
    }
  }
`

const QuantityIconWrapper = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  width: 24px;
  height: 24px;
  padding: 1px;
  border: solid 1px ${colors.divider};
  border-radius: 2px;
`

const Quantity = styled.div`
  ${smallcaps}
  padding: 0 10px;
  color: ${colors.obsidian};
`

export interface ItemProps {
  item: CartItem
  onUpdateCartItem: (cartItem: CartItem) => void
  onRemoveCartItem: (item: CartItem) => void
}

const Item: React.FC<ItemProps> = ({
  item,
  onUpdateCartItem,
  onRemoveCartItem,
}) => {
  const handleUpdateCartItem = (quantity) => {
    onUpdateCartItem({ ...item, quantity })
  }

  const handleUpdateCartItemDebounced = _.debounce(handleUpdateCartItem, 500, {
    leading: true,
    trailing: true,
    maxWait: 1000,
  })

  const [localQuantity, setLocalQuantity] = React.useState<number>(
    item.quantity
  )

  const incrementQuantity = () => {
    const updatedQuantity = localQuantity + 1
    setLocalQuantity(updatedQuantity)
    handleUpdateCartItemDebounced(updatedQuantity)
  }

  const decrementQuantity = () => {
    const updatedQuantity = localQuantity + -1
    setLocalQuantity(updatedQuantity)
    handleUpdateCartItemDebounced(updatedQuantity)
  }

  const handleOnRemoveCartItem = () => {
    onRemoveCartItem(item)
  }

  return (
    <Container>
      <Column style={{ flexShrink: 0 }}>
        <Link href={getProductUrl(item.productSlug)} passHref>
          <ImageLink>
            <ImageContainer>
              <Image src={item.image} />
            </ImageContainer>
          </ImageLink>
        </Link>
      </Column>
      <Column>
        <Content>
          <Name>{item.productName}</Name>
          <VerticalSpace height={10} />
          {item.options.map((option, i) => (
            <Option
              key={i}
            >{`${option.optionType}: ${option.optionValue}`}</Option>
          ))}
          <VerticalSpace height={20} />
          <QuantityInputs>
            <SquareIconButton
              onClick={decrementQuantity}
              disabled={localQuantity === 1}
            >
              <QuantityIconWrapper>
                <RemoveMinus strokeColor={colors.slateLight} />
              </QuantityIconWrapper>
            </SquareIconButton>
            <Quantity>{localQuantity}</Quantity>
            <SquareIconButton onClick={incrementQuantity}>
              <QuantityIconWrapper>
                <AddPlus strokeColor={colors.slateLight} />
              </QuantityIconWrapper>
            </SquareIconButton>
          </QuantityInputs>
        </Content>
      </Column>
      <Column style={{ flexShrink: 0 }}>
        <Price>{formatCurrency(parseFloat(item.price), "USD", "en-US")}</Price>
        <TrashButton onClick={handleOnRemoveCartItem}>
          <Trash strokeColor={colors.mediumGray} />
        </TrashButton>
      </Column>
    </Container>
  )
}

export default Item
