import React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"
import { Formik, Form } from "formik"
import Link from "next/link"
import colors from "@constants/colors"
import { bodyBold, subtextBold, h5 } from "@constants/typography"
import VerticalSpace from "@components/VerticalSpace"
import Button from "@components/buttons/Button"
import TextField from "@components/form/TextField"
import LoginSchema, { initialValues } from "./schema"
import { CustomerLogin, CustomerLoginResponse } from "@redux/types"

const Container = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%;

  form {
    position: relative;
    width: 100%;

    &:active {
      outline: none;
    }

    &:focus {
      outline: none;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus {
      box-shadow: 0 0 0 1000px ${colors.lightestGray} inset !important;
      color: ${colors.obsidian} !important;
    }
  }

  ${Button} {
    justify-content: flex-start;
    padding: 0 16px;
  }
`

const Title = styled.div`
  ${h5}
  text-align: center;
  color: ${colors.obsidian};

  ${up("laptop")} {
    text-align: left;
  }
`

const FieldWrapper = styled.div`
  width: 100%;
  margin-bottom: 24px;
`

const ButtonWrapper = styled.div`
  width: 100%;
`

const FooterLink = styled.a`
  ${bodyBold}
  color: ${colors.slateDark};
`

const FooterButton = styled.button`
  ${bodyBold}
  outline: none;
  border: none;
  background: none;
  color: ${colors.slateDark};

  &:focus,
  &:active {
    outline: none;
  }

  ${up("laptop")} {
    &:hover {
      cursor: pointer;
    }
  }
`

const Emphasized = styled.span`
  ${bodyBold}
  color: ${colors.obsidian};
`

const ErrorMessage = styled.div`
  ${subtextBold}
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  text-align: center;
  color: ${colors.error};
`

export interface LoginFormProps {
  onClickSignUp?: React.ReactEventHandler
  onSubmit: (values: CustomerLogin) => Promise<CustomerLoginResponse>
  onSuccess?: () => void
}

const LoginForm: React.FC<LoginFormProps> = ({
  onClickSignUp,
  onSubmit,
  onSuccess,
}) => {
  const [errorMessage, setErrorMessage] = React.useState<string>("")

  const handleSubmit = async (values) => {
    const { success, error } = await onSubmit(values)

    if (success) {
      setErrorMessage("")
      if (onSuccess) {
        onSuccess()
      }
    } else {
      setErrorMessage(error)
    }
  }

  return (
    <Formik
      validationSchema={LoginSchema}
      isInitialValid={false}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {(formik) => {
        const { isValid, isSubmitting } = formik

        return (
          <Container>
            <Form>
              <Title>Log In</Title>
              <VerticalSpace height={34} mobileHeight={44} />
              <FieldWrapper>
                <TextField name="email" placeholder="Email" label="Email" />
              </FieldWrapper>
              <FieldWrapper>
                <TextField
                  type="password"
                  name="password"
                  placeholder="Password"
                  label="Password"
                />
              </FieldWrapper>
              <VerticalSpace height={8} />
              <ButtonWrapper>
                <Button type="submit" disabled={!isValid || isSubmitting}>
                  Log In
                </Button>
              </ButtonWrapper>
              {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            </Form>
            <VerticalSpace height={32} />
            {onClickSignUp ? (
              <FooterButton onClick={onClickSignUp}>
                Don't have an account? <Emphasized>Sign Up</Emphasized>
              </FooterButton>
            ) : (
              <Link href="/signup" passHref>
                <FooterLink>
                  Don't have an account? <Emphasized>Sign Up</Emphasized>
                </FooterLink>
              </Link>
            )}
          </Container>
        )
      }}
    </Formik>
  )
}

export default LoginForm
