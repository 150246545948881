import React from "react"
import styled, { css } from "styled-components"
import { up } from "styled-breakpoints"
import { columns14, columnsInMaxWidth } from "@constants/layout"

const Container = styled.div<{ cols: number }>`
  ${({ cols }) => css`
    width: 100%;

    ${up("laptop")} {
      width: ${columns14(cols)};
    }

    ${up("maxWidth")} {
      width: calc(${columnsInMaxWidth(cols)});
    }
  `}
`

export interface MaxWidthContentProps {
  cols: number
  children?: React.ReactNode
  style?: React.CSSProperties
}

const MaxWidthContent: React.FC<MaxWidthContentProps> = ({
  cols,
  children,
  style,
}) => {
  return (
    <Container cols={cols} style={style}>
      {children}
    </Container>
  )
}

export default MaxWidthContent
