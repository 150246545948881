import React from "react"
import styled, { css } from "styled-components"
import colors from "@constants/colors"
import { timings, cubicBezier } from "@constants/animation"
import Header from "./Header"
import Item from "./Item"
import VerticalSpace from "@components/VerticalSpace"

const openProps = css`
  transform: translateX(0);
  transition: transform ${timings.medium} ${cubicBezier};
`
const Container = styled.div<{ isOpen?: boolean }>`
  ${({ isOpen }) => css`
    z-index: 3;
    position: absolute;
    top: 0;
    opacity: 1;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    transform: translateX(100%);
    transition: transform ${timings.medium} ${cubicBezier};
    will-change: transform;
    width: 100%;
    height: 100%;
    background-color: ${colors.snow};
    cursor: default;

    ${isOpen ? openProps : ""}
  `}
`

const overlayActiveProps = css`
  opacity: 0.1;
  transition: opacity ${timings.medium} ${cubicBezier};
`
const Overlay = styled.div<{ isVisible: boolean }>`
  ${({ isVisible }) => css`
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity ${timings.long} ${cubicBezier};
    will-change: opacity;
    height: 100%;
    width: 100%;
    background-color: ${colors.black};
    pointer-events: none;

    ${isVisible ? overlayActiveProps : ""}
  `}
`

interface SubMenuProps {
  isOpen: boolean
  label: string
  items: Array<{
    label: string
    link: string
  }>
  onRequestBack?: () => void
  onRequestClose: () => void
}

const SubMenu: React.FC<SubMenuProps> = ({
  isOpen,
  label,
  items,
  onRequestBack,
  onRequestClose,
}) => {
  return (
    <>
      <Overlay isVisible={isOpen} />
      <Container isOpen={isOpen}>
        <Header
          label={label}
          onRequestBack={onRequestBack}
          onRequestClose={onRequestClose}
        />
        <VerticalSpace height={8} />
        {items.map((item, i) => (
          <Item key={i} {...item} onClick={onRequestClose} />
        ))}
      </Container>
    </>
  )
}

export default SubMenu
