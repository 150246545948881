import React from "react"
import MobilePromoBanner from "./MobilePromoBanner"
import DesktopPromoBanner from "./DesktopPromoBanner"
import { PromoBannerProps } from "./common"

const PromoBanner: React.FC<PromoBannerProps> = ({
  promoBannerLinks,
  promos,
}) => {
  return (
    <>
      <MobilePromoBanner promoBannerLinks={promoBannerLinks} promos={promos} />
      <DesktopPromoBanner promoBannerLinks={promoBannerLinks} promos={promos} />
    </>
  )
}

export default PromoBanner
