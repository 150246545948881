import React from "react"
import Form from "./Form"
import Verification from "./Verification"
import { CustomerSignup, CustomerSignupResponse } from "@redux/types"

export interface SignupFormProps {
  onClickLogin?: React.ReactEventHandler
  onSubmit: (values: CustomerSignup) => Promise<CustomerSignupResponse>
  onDismissVerification?: React.ReactEventHandler
}

const SignupForm: React.FC<SignupFormProps> = ({
  onClickLogin,
  onSubmit,
  onDismissVerification,
}) => {
  const [showVerification, setShowVerification] = React.useState<boolean>(false)
  const [errorMessage, setErrorMessage] = React.useState<string>("")

  const handleSubmit = async (values) => {
    const { success, error } = await onSubmit(values)

    if (success) {
      setErrorMessage("")
      setShowVerification(true)
    } else {
      setErrorMessage(error)
    }
  }

  return showVerification ? (
    <Verification onDismissVerification={onDismissVerification} />
  ) : (
    <Form
      errorMessage={errorMessage}
      onClickLogin={onClickLogin}
      onSubmit={handleSubmit}
    />
  )
}

export default SignupForm
