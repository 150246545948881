import * as yup from "yup"
import { CustomerSignup } from "@redux/types"

export const initialValues: CustomerSignup = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
}

export default yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup.string().required("Password is required"),
})
