import React from "react"
import styled, { css } from "styled-components"
import colors from "@constants/colors"
import { timings, cubicBezier } from "@constants/animation"
import DesktopSlideout from "@components/Layout/DesktopSlideout"
import LoginForm from "@components/LoginForm/hoc/withStore"
import SignupForm from "@components/SignupForm/hoc/withStore"

const overlayActiveProps = css`
  opacity: 0.1;
  transition: opacity ${timings.medium} ${cubicBezier};
`

const Overlay = styled.div<{ isActive: boolean }>`
  z-index: 3;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  will-change: opacity;
  transition: opacity ${timings.medium} ${cubicBezier};
  height: 100%;
  width: 100%;
  background-color: ${colors.black};
  pointer-events: none;
  ${({ isActive }) => (isActive ? overlayActiveProps : "")}
`

const LoginFormWrapper = styled.div`
  width: 100%;
  padding: 0 60px;
`

const signupFormWrapperOpenProps = css`
  transform: translateX(0);
  transition: transform ${timings.medium} ${cubicBezier};
`

const SignupFormWrapper = styled.div<{ isOpen: boolean }>`
  z-index: 3;
  position: absolute;
  top: 0;
  opacity: 1;
  transform: translateX(100%);
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  will-change: transform;
  transition: transform ${timings.medium} ${cubicBezier};
  width: 100%;
  height: 100%;
  padding: 0 60px;
  background-color: ${colors.snow};
  cursor: default;

  ${({ isOpen }) => (isOpen ? signupFormWrapperOpenProps : "")}
`

interface UserSlideoutProps {
  isOpen: boolean
  onRequestClose?: () => void
}

const UserSlideout: React.FC<UserSlideoutProps> = ({
  isOpen,
  onRequestClose,
}) => {
  const [showSignupForm, setShowSignupForm] = React.useState<boolean>(false)

  const openSignupForm = () => {
    setShowSignupForm(true)
  }

  const closeSignupForm = () => {
    setShowSignupForm(false)
  }

  return (
    <DesktopSlideout isOpen={isOpen} onRequestClose={onRequestClose}>
      <LoginFormWrapper>
        <LoginForm onClickSignUp={openSignupForm} onSuccess={onRequestClose} />
      </LoginFormWrapper>
      <Overlay isActive={showSignupForm} />
      <SignupFormWrapper isOpen={showSignupForm}>
        <SignupForm
          onClickLogin={closeSignupForm}
          onDismissVerification={onRequestClose}
        />
      </SignupFormWrapper>
    </DesktopSlideout>
  )
}

export default UserSlideout
