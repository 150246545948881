import React from "react"
import styled, { css } from "styled-components"
import colors from "@constants/colors"
import { body, bodyBold } from "@constants/typography"
import { DESKTOP_HEADER_HEIGHT } from "@constants/measurements"
import { MenuStates } from "./common"
import Link from "next/link"

const Container = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  min-width: 400px;
  height: 60px;
  border-bottom: 1px solid ${colors.divider};
  background-color: ${colors.black};
`

const Content = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%;
`

const activeProps = css`
  color: ${colors.snow};
  border-bottom: 2px solid ${colors.snow};
  transition: 0.6s ease-out;
`
const Button = styled.div<{ active }>`
  ${({ active }) => css`
    font-family: "Jost", sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    align-items: center;
    display: flex;
    height: 100%;
    margin: 0 10px;
    padding: 0;
    outline: none;
    border-bottom: 2px solid ${colors.black};
    background: none;
    color: ${colors.snow};
    padding-bottom: 10px;

    ${active ? activeProps : ""}

    &:focus,
    &:active {
      outline: none;
    }

    &:hover {
      cursor: pointer;
    }
  `}
`

interface HeaderProps {
  menuState?: undefined | MenuStates
  onResetVehicleSelectorMenu?: () => void
  onClickVehicleSelectorMenu?: () => void
  onClickProSeriesMenu?: () => void
  onClickMainMenu?: () => void
}

const Header: React.FC<HeaderProps> = ({
  menuState,
  onResetVehicleSelectorMenu,
  onClickVehicleSelectorMenu,
  onClickProSeriesMenu,
  onClickMainMenu,
}) => {
  return (
    <Container>
      <Content>
        <Button
          onClick={() => {
            if (onResetVehicleSelectorMenu && menuState === MenuStates.Picker) {
              onResetVehicleSelectorMenu()
            }
            if (onClickVehicleSelectorMenu && menuState !== MenuStates.Picker) {
              onClickVehicleSelectorMenu()
            }
          }}
          active={menuState && menuState === MenuStates.Picker}
        >
          Shop by Vehicle
        </Button>
        <Button
          onClick={() => {
            if (onClickProSeriesMenu) {
              onClickProSeriesMenu()
            }
          }}
          active={menuState && menuState === MenuStates.ProSeries}
        >
          Seats
        </Button>
        <Button
          onClick={() => {
            if (onClickMainMenu) {
              onClickMainMenu()
            }
          }}
          active={menuState && menuState === MenuStates.FullMenu}
        >
          Full Menu
        </Button>
        <Button onClick={() => {}} active={false}>
          <Link href={"/configurator"}>Configurator</Link>
        </Button>
      </Content>
    </Container>
  )
}

export default Header
