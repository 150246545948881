import React from "react"
import styled, { css } from "styled-components"
import { up } from "styled-breakpoints"
import { columns14 } from "@constants/layout"
import measurements from "@constants/measurements"

const Container = styled.div`
  width: 100%;
`

export const Content = styled.div<{ noMobilePadding?: boolean }>`
  ${({ noMobilePadding }) => css`
    align-items: flex-start;
    justify-content: flex-start;
    display: flex;
    width: 100%;
    margin: 0 auto;
    padding: ${noMobilePadding ? "0" : "0 20px"};

    ${up("laptop")} {
      padding: 0 ${columns14(1)};
    }

    ${up("maxWidth")} {
      max-width: ${measurements.MAX_WIDTH};
      padding: 0;
    }
  `}
`

interface MaxWidthContainerProps {
  children?: React.ReactNode
  noMobilePadding?: boolean
  containerStyle?: React.CSSProperties
  contentStyle?: React.CSSProperties
}

const MaxWidthContainer: React.FC<MaxWidthContainerProps> = ({
  children,
  noMobilePadding,
  containerStyle,
  contentStyle,
}) => {
  return (
    <Container style={containerStyle}>
      <Content noMobilePadding={noMobilePadding} style={contentStyle}>
        {children}
      </Content>
    </Container>
  )
}

export default MaxWidthContainer
