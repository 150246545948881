import colors from "./colors"
import { css } from "styled-components"

export const body = css`
  font-family: "Inter", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;
  color: ${colors.slateDark};
`

export const bodyBold = css`
  ${body}
  font-weight: 550;
`

export const subtext = css`
  ${body}
  font-size: 12px;
  line-height: 16px;
  color: ${colors.slateDark};
`

export const subtextBold = css`
  ${subtext};
  font-weight: 500;
`

export const smallcaps = css`
  font-family: "Inter", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  color: ${colors.slateDark};
`

export const h1 = css`
  ${body}
  font-weight: 700;
  font-size: 58px;
  line-height: 68px;
`

export const h2 = css`
  ${body}
  font-weight: 700;
  font-size: 46px;
  line-height: 56px;
`

export const h3 = css`
  ${body}
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
`

export const h4 = css`
  ${body}
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
`

export const h5 = css`
  ${body}
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
`

export const h6 = css`
  ${body}
  font-weight: 600;
  font-size: 19px;
`
