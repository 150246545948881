import styled from "styled-components"
import { up } from "styled-breakpoints"
import Link from "next/link"
import colors from "@constants/colors"
import measurements from "@constants/measurements"
import { bodyBold } from "@constants//typography"

export const StyledLink = styled.a`
  ${bodyBold}
  align-items: center;
  justify-content: center;
  display: flex;
  outline: none;
  width: 100%;
  height: ${measurements.LINK_BUTTON_HEIGHT};
  padding: 0 24px;
  border: none;
  border-radius: 2px;
  background-color: ${colors.snow};
  cursor: pointer;
  text-align: center;
  color: ${colors.obsidian};

  ${up("laptop")} {
    width: initial;

    &:hover {
      cursor: pointer;
    }
  }
`

export interface LinkButtonProps {
  href: string
  children: React.ReactNode
  onClick?: () => void
}

const LinkButton: React.FC<LinkButtonProps> = ({
  href,
  children,
  onClick,
  ...rest
}) => {
  return (
    <Link href={href} passHref>
      <StyledLink {...rest}>{children}</StyledLink>
    </Link>
  )
}

export default LinkButton
