import styled from "styled-components"
import colors from "@constants/colors"
import measurements from "@constants/measurements"

export default styled.button`
  align-items: center;
  justify-content: center;
  display: flex;
  width: ${measurements.SQUARE_BUTTON_DIMENSIONS};
  height: ${measurements.SQUARE_BUTTON_DIMENSIONS};
  padding: 12px;
  outline: none;
  border: none;
  border-radius: 2px;
  background: ${colors.divider};
  cursor: pointer;
`
