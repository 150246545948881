import * as yup from "yup"

export type NewsletterFormValues = {
  firstName?: string
  lastName?: string
  email: string
  make?: string
  model?: string
  yaer?: string
}

export const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  make: "",
  model: "",
  year: "",
}

export default yup.object().shape({
  firstName: yup.string(),
  lastName: yup.string(),
  email: yup.string().email("Invalid email").required("Email is required"),
  make: yup.string(),
  model: yup.string(),
  year: yup.string(),
})
