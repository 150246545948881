// import "slick-carousel/slick/slick.css"
// import "slick-carousel/slick/slick-theme.css"
import React from "react"
import Slider from "react-slick"
import styled, { css } from "styled-components"
import { up } from "styled-breakpoints"
import Link from "next/link"
import colors from "@constants/colors"
import { timings, cubicBezier } from "@constants/animation"
import { body, subtext, h6 } from "@constants/typography"
import { Chevron, Close } from "@components/icons"
import { IconWrapper } from "@components/icons/utils"
import Button from "@components/buttons/Button"
import { PromoBannerProps } from "./common"
import useModalBackgroundClose from "@hooks/useModalBackgroundClose"
import { getIn } from "@utils/index"

const baseButtonStyles = css`
  border: none;
  background-color: transparent;
  outline: none;

  &:focus {
    outline: none;
  }

  &:active {
    outline: none;
  }
`

const Container = styled.div`
  padding: 8px 0;
  height: 49px;
  background-color: ${colors.red};

  ${up("laptop")} {
    display: none;
  }

  .slick-track {
    height: 32px;
    overflow: hidden;
  }
`

const ExpandedContent = styled.div<{ expanded: boolean }>`
  ${({ expanded }) => css`
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(${expanded ? "0" : "-100%"});
    transition: transform ${timings.medium} ${cubicBezier};
    width: 100%;
    padding: 50px 20px 0;
    border-bottom: solid 1px ${colors.divider};
    background-color: ${colors.snow};
  `}
`

const ExpandedHeader = styled.div`
  ${h6}
  padding-right: 40px;
`

const ExpandedBody = styled.div`
  ${body};
  margin: 30px 0 36px;
  padding-right: 20px;
  color: ${colors.slateDark};
  max-height: calc(100vh - 300px);
  overflow-y: auto;

  p {
    margin: 0 0 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    margin: 0 0 20px;
    padding-left: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const ExternalLink = styled.a``

const CloseButton = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`

const CollapseButton = styled.button`
  ${baseButtonStyles}
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 56px;
  margin-top: 32px;
`

const CollapseIconWrapper = styled(IconWrapper)`
  transform: rotate(180deg);
`

const PromoWrapper = styled.div`
  padding: 0 8px;
`

const Promo = styled.button`
  ${baseButtonStyles}
  align-items: center;
  display: flex;
  width: calc(100vw - 64px) !important;
  height: 32px;
  padding: 0 16px 0 18px;
  border-radius: 2px;
  font-family: "Jost", sans-serif;
`

const PromoHeader = styled.div`
  ${subtext}
  overflow: hidden;
  width: 100%;
  font-size: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${colors.snow};
`

const PromoIconWrapper = styled(IconWrapper)`
  flex-shrink: 0;
  transform: rotate(0);
  margin-left: 2px;

  svg {
    path {
      stroke: #fff;
    }
  }
`

const MobilePromoBanner: React.FC<PromoBannerProps> = ({ promos }) => {
  const [swiped, setSwiped] = React.useState(false)

  const handleSwiped = React.useCallback(() => {
    setSwiped(true)
  }, [setSwiped])

  const [expanded, setExpanded] = React.useState<boolean>(false)

  const expandBody = (event) => {
    event.stopPropagation()
    setExpanded(true)
  }

  const collapseBody = () => {
    setExpanded(false)
  }

  const [promoIndex, setPromoIndex] = React.useState<number>(0)

  const getClickHandlerForPromo = (i) =>
    React.useCallback(
      (e) => {
        if (swiped) {
          e.stopPropagation()
          e.preventDefault()
          setSwiped(false)
        } else {
          setPromoIndex(i)
          expandBody(e)
        }
      },
      [swiped]
    )

  const expandedContainerRef: any = React.useRef(null)

  useModalBackgroundClose({
    modalRef: expandedContainerRef,
    isOpen: expanded,
    onRequestClose: collapseBody,
  })

  return (
    <Container>
      {promos?.length > 0 && (
        <ExpandedContent ref={expandedContainerRef} expanded={expanded}>
          <ExpandedHeader>{promos[promoIndex].header}</ExpandedHeader>
          <ExpandedBody
            dangerouslySetInnerHTML={{
              __html: getIn(promos[promoIndex], "body"),
            }}
          />
          {promos[promoIndex].link && (
            <Link href={promos[promoIndex].link!} passHref>
              <Button>{promos[promoIndex].buttonText || "Go"}</Button>
            </Link>
          )}
          {promos[promoIndex].externalLink && (
            <ExternalLink
              href={promos[promoIndex].externalLink}
              target="_blank"
              rel="noopener"
            >
              <Button>{promos[promoIndex].buttonText || "Go"}</Button>
            </ExternalLink>
          )}
          <CloseButton onClick={collapseBody}>
            <IconWrapper>
              <Close strokeColor={colors.obsidian} />
            </IconWrapper>
          </CloseButton>
          <CollapseButton onClick={collapseBody}>
            <CollapseIconWrapper>
              <Chevron strokeColor={colors.obsidian} />
            </CollapseIconWrapper>
          </CollapseButton>
        </ExpandedContent>
      )}
      <Slider
        dots={false}
        arrows={false}
        autoplay={true}
        autoplaySpeed={5000}
        infinite
        centerMode
        swipeToSlide
        variableWidth
        onSwipe={handleSwiped}
      >
        {promos.map(({ header }, i) => (
          <PromoWrapper
            key={`${i}-${header}`}
            onClickCapture={getClickHandlerForPromo(i)}
          >
            <Promo>
              <PromoHeader>{header}</PromoHeader>
              <PromoIconWrapper>
                <Chevron strokeColor={colors.obsidian} />
              </PromoIconWrapper>
            </Promo>
          </PromoWrapper>
        ))}
      </Slider>
    </Container>
  )
}

export default MobilePromoBanner
