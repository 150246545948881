import React from "react"
import colors from "@constants/colors"

interface IconProps extends React.SVGProps<any> {
  strokeColor?: string
  fillColor?: string
}

export const Logo: React.FC<IconProps> = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 204 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>TMI Automotive Products Icon</title>
    <path
      d="M176.559 19.147v-2.684h1.312v-.537h-3.519v.537h1.312v2.684h.895zM182.641 19.147v-3.22h-1.312l-.775 2.206-.835-2.207h-1.313v3.221h.835v-2.564l.835 2.564h.895l.835-2.564v2.564h.835z"
      fill="#231F20"
    />
    <path
      d="M14.846 50.583c-2.863-7.457 12.467-16.285 21.772-20.878l-.179-.537c-77.424 29.706-17.716 45.93 51.12 43.902 54.996-1.61 116.077-19.923 116.077-19.923-35.372 5.786-65.197 11.572-89.832 13.242-65.614 4.594-95.08-5.726-98.958-15.806z"
      fill="#EC2127"
    />
    <path
      d="M36.978 30.779l.179.418 10.617-16.165H31.61l4.832 14.136.179.537.358 1.074zM59.106 15.032L40.316 44.26H28.625l5.846 17.238h12.466L64.892 32.33h7.873l.776-1.193 10.498-16.702-.298-2.028c.596-.12 1.193-.239 1.79-.298l2.982-4.772c-1.73.417-3.52.895-5.31 1.372l-.715-5.13-7.993 11.333-15.39.12zM101.814 27.797l12.943-12.825-2.922-6.442c3.579-.358 6.979-.656 10.319-.895l4.175-4.116h19.327l-26.365 40.502-13.421-.06 15.747-24.396-23.86 24.396H87.438l6.442-24.873L78.73 44.02H65.546l5.666 17.418h13.6l5.369-7.636 2.744 7.636h10.438l6.264-6.264 2.207 6.264h13.957l6.622-11.214 3.698 11.214h12.109L173.75 21.89l-3.34-12.287c10.677 2.386 16.702 6.143 18.611 10.2 5.129 10.856-27.081 28.094-27.081 28.094 87.386-41.277 22.786-54.042-49.867-45.333-1.909.239-4.056.537-6.323.954h3.937l-7.873 24.277zm51.536-20.46l2.386-3.818h12.944l-26.842 40.442-11.274-.06 18.134-29.108-2.088-7.754c2.386.06 4.593.179 6.74.298z"
      fill="#231F20"
    />
    <path
      d="M37.155 31.197L28.625 44.2h11.691l18.79-29.168 15.39-.12L82.487 3.52H38.885l-7.277 11.513h16.164L37.155 31.197zM84.043 14.435L73.545 31.077l-8.053 12.765v.18h13.242l15.151-24.934-6.502 24.873h10.32l23.919-24.396-15.747 24.337 13.421.12 26.365-40.503h-19.327l-4.175 4.176-20.34 20.102 7.873-24.278H90.843l-2.326 3.758-2.983 4.772-1.49 2.386zM130.566 43.902l11.274.06 26.842-40.443h-12.944l-2.386 3.818-4.652 7.456-18.134 29.109z"
      fill="#EC2127"
    />
  </svg>
)

// Used in favicon and views/BlogEntry as per request by Christian
export const LogoV2: React.FC<IconProps> = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 100 45.63"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>TMI Automotive Products Icon</title>
    <path
      fill="#e1251b"
      d="M71.11 38.77c-5.67 1.75-12.01 3.1-18.74 3.89-26.2 3.09-48.5-3.4-49.81-14.49-.6-5.08 3.32-10.25 10.27-14.66-2.97 2.52-4.55 5.43-4.32 8.54.75 10.04 20.03 18.39 43.06 18.65 7.13.08 13.77-.63 19.54-1.93z"
    />
    <path d="M77.99 35.82c-.05.11-.09.19-.11.24-.02-.05-.05-.12-.1-.22l-.48-1.07h-.41v1.51h.26v-1.29l.59 1.29h.24l.59-1.26v1.26h.26v-1.51h-.36l-.48 1.05zM75 34.95h.68v1.33h.27v-1.33h.67v-.18H75z" />
    <path d="M97.44 17.01C96.14 5.92 73.84-.56 47.63 2.52c-6.64.78-12.89 2.1-18.51 3.82 5.81-1.33 12.52-2.05 19.72-1.97 9.37.11 18.12 1.55 25.25 3.9l-1.84 2.95-.84-3.09H60.96L56.7 12.2l-1.39-4.06H44.57l-1.51 2.4-.34-2.4H20.85l-3.98 6.31 2.43 7.52-4.02 6.28 3.35 9.11h7.45l8-13.36.56.01-2.43 4.11 3.18 9.24h7.92l1.33-1.88.67 1.88h6.31l1.7-1.71.6 1.71h8.13l1.9-3.18 1.04 3.18h7.28l12.41-19.23-2.16-6.24c5.6 3.12 9.09 6.98 9.4 11.13.21 2.86-1.11 5.55-3.63 7.93 6.24-4.25 9.72-9.13 9.15-13.94z" />
    <path
      fill="#e1251b"
      d="M21.49 9.08l-2.96 4.69h7.28l-8.83 13.78h5.24l8.87-13.81 7 .03 3.29-4.69H21.49zm32.53 0l-4.04 10.94L61.32 9.08h8.87L58.27 27.55h-5.56l6.87-11.19-10.47 11.19h-5.49l3-11.51-7.44 11.51h-5.25L45.17 9.08h8.85zm26.02 0L68.52 27.55H63.2L74.7 9.08h5.34z"
    />
  </svg>
)

export const AddPlus: React.FC<IconProps> = ({
  strokeColor = colors.slateLight,
  strokeWidth = "1.5",
  ...rest
}) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <title>Plus Icon</title>
    <path
      d="M10 6v8M6 10h8"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeMiterlimit="10"
    />
  </svg>
)

export const Arrow: React.FC<IconProps> = ({
  strokeColor = colors.slateDark,
  strokeWidth = 1.5,
  ...rest
}) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <title>Arrow Icon</title>
    <path
      d="M2.02734 10H20.9992M8.7233 3.30395L2.02734 9.99992 8.7233 16.6959"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
    />
  </svg>
)

export const Close: React.FC<IconProps> = ({
  strokeColor = colors.slateDark,
  strokeWidth = "1.5",
  ...rest
}) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <title>Close Icon</title>
    <path
      d="M6 14l8-8M14 14L6 6"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeMiterlimit="10"
    />
  </svg>
)

export const ColorCheckmark: React.FC<IconProps> = ({
  strokeColor = colors.snow,
  strokeWidth = "1.5",
  ...rest
}) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <title>Color Checkmark Icon</title>
    <path
      d="M4.205 8l2.53 2.53 5.06-5.06"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
    />
  </svg>
)

export const Chevron: React.FC<IconProps> = ({
  strokeColor = colors.slateDark,
  strokeWidth = 1.5,
  ...rest
}) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <title>Chevron Icon</title>
    <path
      d="M6.46094 8.961l3.99996 4 4-4"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
    />
  </svg>
)

export const ChevronLarge: React.FC<IconProps> = ({
  strokeColor = colors.slateDark,
  strokeWidth = 1.5,
  ...rest
}) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <title>Chevron Large Icon</title>
    <path
      d="M6.00155 2.00537l7.99455 7.99455-7.99455 7.99458"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
    />
  </svg>
)

export const Gallery: React.FC<IconProps> = ({
  strokeColor = colors.snow,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="100%"
    height="100%"
    fill="none"
    {...rest}
  >
    <path
      d="M22.349 16V4c0-1.1-.9-2-2-2h-12c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2zm-11-4l2.03 2.71 2.97-3.71 4 5h-12l3-4zm-9-6v14c0 1.1.9 2 2 2h14v-2h-14V6h-2z"
      fill={strokeColor}
    />
  </svg>
)

export const Facebook: React.FC<IconProps> = ({
  strokeColor = colors.snow,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="100%"
    height="100%"
    fill="none"
    {...rest}
  >
    <title>Facebook Icon</title>
    <path
      d="M19.1158 4H4.88421C4.6497 4 4.4248 4.09316 4.25898 4.25898C4.09316 4.4248 4 4.6497 4 4.88421V19.1158C4 19.3503 4.09316 19.5752 4.25898 19.741C4.4248 19.9068 4.6497 20 4.88421 20H12.5474V13.8246H10.4561V11.3909H12.5361V9.61404C12.5361 7.54526 13.7965 6.41965 15.6407 6.41965C16.2672 6.42151 16.8931 6.45712 17.5158 6.52632V8.68491H16.2414C15.2393 8.68491 15.0456 9.1593 15.0456 9.85825V11.4021H17.4456L17.134 13.8246H15.0456V20H19.1158C19.3503 20 19.5752 19.9068 19.741 19.741C19.9068 19.5752 20 19.3503 20 19.1158V4.88421C20 4.6497 19.9068 4.4248 19.741 4.25898C19.5752 4.09316 19.3503 4 19.1158 4V4Z"
      fill={strokeColor}
    />
  </svg>
)

export const Info: React.FC<IconProps> = ({
  strokeColor = colors.slateLight,
  strokeWidth = "1.5",
  ...rest
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    width="100%"
    height="100%"
    fill="none"
    {...rest}
  >
    <circle
      cx="10"
      cy="10"
      r="7.25"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
    />
    <path fill={strokeColor} d="M9 9h2v5H9z" />
    <circle cx="9.999" cy="6.767" r="1.234" fill={strokeColor} />
  </svg>
)

export const Instagram: React.FC<IconProps> = ({
  strokeColor = colors.snow,
  ...rest
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="100%"
    height="100%"
    fill="none"
    {...rest}
  >
    <title>Instagram Icon</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.072 4c-2.193 0-2.467.01-3.328.049-.86.039-1.446.175-1.96.375-.53.206-.98.482-1.43.93a3.957 3.957 0 0 0-.93 1.43c-.2.514-.336 1.1-.375 1.96-.04.86-.049 1.135-.049 3.328 0 2.192.01 2.467.049 3.328.039.859.175 1.445.375 1.959.206.53.482.98.93 1.43.45.448.9.724 1.43.93.514.2 1.1.336 1.96.376.86.039 1.135.048 3.328.048 2.192 0 2.467-.01 3.328-.048.859-.04 1.445-.176 1.959-.376.53-.206.98-.482 1.43-.93.448-.45.724-.9.93-1.43.2-.514.336-1.1.376-1.96.039-.86.048-1.135.048-3.327 0-2.193-.01-2.467-.048-3.328-.04-.86-.176-1.446-.376-1.96a3.958 3.958 0 0 0-.93-1.43 3.956 3.956 0 0 0-1.43-.93c-.514-.2-1.1-.336-1.96-.375-.86-.04-1.135-.049-3.327-.049zm0 1.454c2.155 0 2.41.009 3.261.047.787.036 1.214.168 1.499.278a2.5 2.5 0 0 1 .928.604 2.5 2.5 0 0 1 .604.928c.11.285.242.712.278 1.499.039.851.047 1.106.047 3.262 0 2.155-.008 2.41-.047 3.261-.036.787-.168 1.214-.278 1.499a2.5 2.5 0 0 1-.604.928 2.5 2.5 0 0 1-.928.604c-.285.11-.712.242-1.499.278-.85.039-1.106.047-3.261.047-2.156 0-2.411-.008-3.262-.047-.787-.036-1.214-.168-1.499-.278a2.5 2.5 0 0 1-.928-.604 2.5 2.5 0 0 1-.604-.928c-.11-.285-.242-.712-.278-1.499-.038-.85-.047-1.106-.047-3.261 0-2.156.009-2.41.047-3.262.036-.787.168-1.214.278-1.499a2.5 2.5 0 0 1 .604-.928 2.5 2.5 0 0 1 .928-.604c.285-.11.712-.242 1.499-.278.851-.038 1.106-.047 3.262-.047z"
      fill={strokeColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.07 14.761a2.69 2.69 0 1 1 0-5.38 2.69 2.69 0 0 1 0 5.38zm0-6.835a4.145 4.145 0 1 0 0 8.29 4.145 4.145 0 0 0 0-8.29zM17.347 7.764a.969.969 0 1 1-1.937 0 .969.969 0 0 1 1.937 0"
      fill={strokeColor}
    />
  </svg>
)

export const MagnifyingGlass: React.FC<IconProps> = ({
  strokeColor = colors.slateDark,
  strokeWidth = "1.5",
  ...rest
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65.86 65.84" {...rest}>
    <g id="objkt">
      <g>
        <path
          d="m29.14,57.76c-7.44,0-14.9-2.85-20.59-8.54C3.07,43.75.03,36.48,0,28.76c-.03-7.72,2.94-14.96,8.38-20.4,11.22-11.22,29.55-11.14,40.86.17,5.48,5.48,8.51,12.75,8.55,20.46.03,7.72-2.94,14.96-8.38,20.4-5.58,5.58-12.92,8.36-20.28,8.36Zm-.48-55.56c-6.8,0-13.57,2.57-18.72,7.72-5.02,5.02-7.77,11.71-7.73,18.84.03,7.13,2.84,13.85,7.9,18.92,10.45,10.45,27.4,10.53,37.76.17,5.02-5.02,7.77-11.71,7.74-18.84-.03-7.13-2.84-13.85-7.9-18.92-5.26-5.26-12.16-7.89-19.04-7.89Z"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
        />
        <path
          d="m64.76,65.84c-.28,0-.56-.11-.78-.32l-16.12-16.12c-.43-.43-.43-1.12,0-1.55s1.12-.43,1.55,0l16.12,16.12c.43.43.43,1.12,0,1.55-.21.21-.5.32-.78.32Z"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
        />
      </g>
    </g>
  </svg>
)

export const MasterCard: React.FC<IconProps> = ({ ...rest }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 28 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <title>MasterCard Icon</title>
    <circle cx="9.43578" cy="9.0471" r="8.6389" fill="#fff" />
    <circle cx="19.2092" cy="9.04662" r="8.6389" fill="#A2A2A2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.3228 16.1727c2.2664-1.5572 3.7526-4.1676 3.7526-7.12517 0-2.95755-1.4862-5.56797-3.7526-7.12517-2.2663 1.5572-3.7525 4.16762-3.7525 7.12517 0 2.95757 1.4862 5.56797 3.7525 7.12517z"
      fill="#D0D0D0"
    />
  </svg>
)

export const Menu: React.FC<IconProps> = ({
  strokeColor = colors.slateDark,
  strokeWidth = "1.5",
  ...rest
}: IconProps) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 27 27"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <title>Menu Icon</title>
    <path
      d="M2.20312 8.1582H19.5098"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
    />
    <path
      d="M2.20312 14.1582H19.5098"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
    />
    <path
      d="M2.20312 20.1582H19.5098"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
    />
  </svg>
)

export const Phone: React.FC<IconProps> = ({
  strokeColor = colors.snow,
  ...rest
}) => (
  <svg
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 34 33.98"
    fill="none"
    {...rest}
  >
    <g id="objkt">
      <path
        stroke={strokeColor}
        strokeWidth="1"
        d="m26.25,32.98c-6.13,0-12.47-2.82-17.39-7.74l-.1-.1c-2.32-2.32-4.16-4.94-5.47-7.7C1.74,14.17.84,10.23,1.02,7.29c.07-.48.09-.44.3-.82s.88-1.06,1.33-1.51c1.67-1.67,3.63-2.95,5.84-3.8.27-.1.57-.16.9-.16.74,0,2.41.38,2.84,1.55.75,2.09,1.7,4.82,2.39,7.42.2.75-.03,2.52-1.02,3.14,0,0-2.32,1.48-2.43,1.54s.01.4.33.83c.94,1.29,2.04,2.57,3.25,3.81,1.22,1.2,2.5,2.29,3.78,3.22.25.18.8.38.9.27s1.48-2.38,1.48-2.38c.67-1.04,2.36-1.07,2.55-1.07s.37.02.53.05c3.21.72,5.12,1.33,7.56,2.44,1.09.49,1.77,2.46,1.29,3.7-.85,2.2-2.13,4.16-3.79,5.83-.47.47-1.16,1.18-1.52,1.34s-1.28.3-1.28.3Z"
      />
    </g>
  </svg>
)

export const Pencil: React.FC<IconProps> = ({
  strokeColor = colors.obsidian,
  ...rest
}) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M18.546 7.138l-1.404-1.405a1 1 0 00-1.415 0l-1.052 1.053a.5.5 0 000 .707l2.112 2.112a.5.5 0 00.707 0l1.052-1.053a1 1 0 000-1.414zM13.644 8.518a.5.5 0 00-.707 0l-7.314 7.314a2 2 0 00-.571 1.173l-.308 2.535 2.535-.308a2 2 0 001.173-.571l7.314-7.315a.5.5 0 000-.707l-2.122-2.121z"
      fill={strokeColor}
    />
  </svg>
)

export const RemoveMinus: React.FC<IconProps> = ({
  strokeColor = colors.slateLight,
  strokeWidth = "1.5",
  ...rest
}) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <title>Plus Icon</title>
    <path
      d="M6 10h8"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeMiterlimit="10"
    />
  </svg>
)

export const ShoppingCart: React.FC<IconProps> = ({
  strokeColor = colors.slateDark,
  strokeWidth = "1.5",
  ...rest
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65.85 56.2" {...rest}>
    <g id="objkt">
      <g>
        <path
          d="m57.3,37.44H24.9c-.48,0-.9-.31-1.05-.77L12.91,2.2H1.1c-.61,0-1.1-.49-1.1-1.1S.49,0,1.1,0h12.62c.48,0,.9.31,1.05.77l10.94,34.48h30.78l6.81-23.1h-30.36c-.61,0-1.1-.49-1.1-1.1s.49-1.1,1.1-1.1h31.83c.35,0,.67.16.88.44.21.28.27.64.17.97l-7.46,25.3c-.14.47-.57.79-1.05.79Z"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
        />
        <g>
          <path
            d="m28.77,56.2c-4,0-7.26-3.23-7.26-7.21s3.26-7.21,7.26-7.21,7.26,3.23,7.26,7.21-3.26,7.21-7.26,7.21Zm0-12.22c-2.79,0-5.07,2.25-5.07,5.01s2.27,5.01,5.07,5.01,5.07-2.25,5.07-5.01-2.27-5.01-5.07-5.01Z"
            stroke={strokeColor}
            strokeWidth={strokeWidth}
          />
          <path
            d="m54.67,56.2c-4,0-7.26-3.23-7.26-7.21s3.26-7.21,7.26-7.21,7.26,3.23,7.26,7.21-3.26,7.21-7.26,7.21Zm0-12.22c-2.79,0-5.07,2.25-5.07,5.01s2.27,5.01,5.07,5.01,5.07-2.25,5.07-5.01-2.27-5.01-5.07-5.01Z"
            stroke={strokeColor}
            strokeWidth={strokeWidth}
          />
        </g>
      </g>
    </g>
  </svg>
)

export const SpeechBubble: React.FC<IconProps> = ({
  strokeColor = colors.snow,
  strokeWidth = "1.5",
  ...rest
}) => (
  <svg
    id="Layer_2"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
  >
    <g id="Layer_1-2" data-name="Layer 1">
      <path
        d="m24,48C10.8,48,0,37.2,0,24S10.8,0,24,0s24,10.8,24,24-10.8,24-24,24Z"
        stroke={strokeColor}
      />
      <path
        className="cls-1"
        d="m16.98,32.76c-.28,0-.57-.01-.85-.03-.45-.03-.82-.36-.91-.81-.09-.44.14-.89.54-1.09.89-.44,1.61-1.02,2.17-1.74-3.46-1.68-5.64-4.73-5.64-8.03,0-5.17,5.21-9.38,11.62-9.38s11.62,4.21,11.62,9.38-5.21,9.38-11.62,9.38c-.15,0-.3,0-.45-.01-1.64,1.51-3.93,2.33-6.48,2.33Zm6.93-19.07c-5.31,0-9.62,3.31-9.62,7.38,0,2.79,2.13,5.39,5.43,6.63.27.1.48.31.58.57.1.26.09.56-.04.81l-.06.12c-.06.13-.12.26-.2.38-.18.3-.38.58-.59.85,1.16-.33,2.17-.92,2.94-1.72.2-.21.47-.31.78-.3.25.02.51.03.77.03,5.31,0,9.62-3.31,9.62-7.38s-4.32-7.38-9.62-7.38Z"
        fill={strokeColor}
        strokeWidth={strokeWidth}
      />
    </g>
  </svg>
)

export const Star: React.FC<IconProps> = ({
  strokeColor = colors.slateDark,
  ...rest
}) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <title>Star Icon</title>
    <path
      d="M8.81673 0L11.0451 5.74959L17.2019 6.09221L12.4224 9.98827L13.9991 15.9496L8.81673 12.6079L3.63439 15.9496L5.21109 9.98827L0.431521 6.09221L6.58832 5.74959L8.81673 0Z"
      fill={strokeColor}
    />
  </svg>
)

export const Trash: React.FC<IconProps> = ({
  strokeColor = colors.slateLight,
  strokeWidth = "1.5",
  ...rest
}) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
    fill="none"
  >
    <title>Trash Icon</title>
    <path
      d="M13.6166 17H6.28328L4.44995 7H15.45l-1.8334 10zM3.44995 4.25H16.5499"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeMiterlimit="10"
    />
    <path
      d="M8.05005 3c0-.55228.44771-1 1-1H11.05c.5523 0 1 .44772 1 1v.5H8.05005V3z"
      fill={strokeColor}
    />
  </svg>
)

export const Twitter: React.FC<IconProps> = ({
  strokeColor = colors.slateDark,
  ...rest
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="100%"
    height="100%"
    fill="none"
    {...rest}
  >
    <title>Twitter Icon</title>
    <path
      d="M21 6.846a7.555 7.555 0 0 1-2.128.57 3.644 3.644 0 0 0 1.63-2 7.537 7.537 0 0 1-2.355.877 3.747 3.747 0 0 0-2.706-1.142c-2.048 0-3.708 1.619-3.708 3.615 0 .283.033.56.096.824a10.617 10.617 0 0 1-7.641-3.777 3.528 3.528 0 0 0-.502 1.817 3.59 3.59 0 0 0 1.65 3.009 3.762 3.762 0 0 1-1.68-.452v.045c0 1.752 1.278 3.213 2.974 3.545a3.804 3.804 0 0 1-1.675.062c.472 1.436 1.841 2.481 3.464 2.51a7.56 7.56 0 0 1-4.605 1.548c-.3 0-.594-.017-.884-.05a10.689 10.689 0 0 0 5.683 1.623c6.82 0 10.549-5.508 10.549-10.285 0-.157-.004-.312-.011-.468A7.426 7.426 0 0 0 21 6.847z"
      fill={strokeColor}
    />
  </svg>
)

export const UserOutline: React.FC<IconProps> = ({
  strokeColor = colors.slateDark,
  strokeWidth = "1.5",
  ...rest
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58.91 65.85" {...rest}>
    <g id="objkt">
      <path
        d="m29.45,37.26c-10.22,0-18.53-8.36-18.53-18.63S19.23,0,29.45,0s18.53,8.36,18.53,18.63-8.31,18.63-18.53,18.63Zm0-35.07c-9.01,0-16.34,7.37-16.34,16.44s7.33,16.43,16.34,16.43,16.34-7.37,16.34-16.43S38.46,2.2,29.45,2.2Z"
        fill={strokeColor}
        strokeWidth={strokeWidth}
      />
      <path
        d="m57.81,65.85c-.61,0-1.1-.49-1.1-1.1,0-15.12-12.23-27.41-27.26-27.41S2.2,49.64,2.2,64.76c0,.61-.49,1.1-1.1,1.1s-1.1-.49-1.1-1.1c0-16.33,13.21-29.61,29.45-29.61s29.45,13.28,29.45,29.61c0,.61-.49,1.1-1.1,1.1Z"
        fill={strokeColor}
        strokeWidth={strokeWidth}
      />
    </g>
  </svg>
)

export const Visa: React.FC<IconProps> = ({
  strokeColor = colors.snow,
  ...rest
}) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 40 14"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <title>Visa Icon</title>
    <path
      d="M26.087 0c-3.4522 0-5.2174 1.74243-5.2174 3.95717 0 4.00626 4.5218 3.46702 4.5218 5.52589 0 .3476-.261 1.17644-2.1566 1.17644-1.9 0-3.1305-.74869-3.1305-.74869l-.5913 2.70949s1.2305.713 3.5827.713 5.5999-1.8449 5.5999-4.52759c0-3.22638-4.5217-3.48043-4.5217-4.91982 0-.73532.5566-1.28343 2.1914-1.28343 1.0608 0 2.2608.82 2.2608.82l.5565-2.780792S27.6043 0 26.087 0zM11.3739.24951L8.38262 9.30483s-.20435-.77101-.27826-1.24785c-1.7087-3.93045-4.48697-5.38325-4.48697-5.38325L6.26088 12.9411h3.61739L14.887.24951h-3.5131zm4.8 0L14.2261 12.9411h3.3044L19.513.24951h-3.3391zm17.8436 0L28.6261 12.9411h3.2696l.6609-1.8181h4.1043l.3478 1.8181H40L37.4609.24951h-3.4434zM0 .285197S6.46087 2.3351 7.96523 7.30835L6.85218 1.53294S6.37392.285197 5.07827.285197H0zM35.2696 3.92158l.9043 4.77717h-2.713l1.8087-4.77717z"
      fill={strokeColor}
    />
  </svg>
)

export const YouTube: React.FC<IconProps> = ({
  strokeColor = colors.snow,
  ...rest
}) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 21 20"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <title>YouTube Icon</title>
    <path
      d="M19.441 6.25105c0-1.40585-1.1215-2.69456-2.4673-2.81171-4.1681-.58579-8.39257-.58579-12.56071 0-.67429.0589-1.30261.38094-1.75984.90199-.45722.52105-.70981 1.20288-.70745 1.90972-.33645 2.46025-.33645 5.03765 0 7.49795 0 1.4058 1.12149 2.6945 2.46729 2.8117 4.14953.5857 8.41121.5857 12.56071 0 1.3458-.2343 2.3552-1.4059 2.4673-2.8117.3365-2.4603.3365-5.0377 0-7.49795zM8.45037 13.5146V6.48536L14.0578 10l-5.60743 3.5146z"
      fill={strokeColor}
    />
  </svg>
)

export const Save: React.FC<IconProps> = ({
  strokeColor = colors.slateDark,
  ...rest
}) => (
  <svg
    fill={strokeColor}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 407.096 407.096"
    {...rest}
  >
    <g>
      <g>
        <path
          d="M402.115,84.008L323.088,4.981C319.899,1.792,315.574,0,311.063,0H17.005C7.613,0,0,7.614,0,17.005v373.086
          c0,9.392,7.613,17.005,17.005,17.005h373.086c9.392,0,17.005-7.613,17.005-17.005V96.032
          C407.096,91.523,405.305,87.197,402.115,84.008z M300.664,163.567H67.129V38.862h233.535V163.567z"
        />
        <path
          d="M214.051,148.16h43.08c3.131,0,5.668-2.538,5.668-5.669V59.584c0-3.13-2.537-5.668-5.668-5.668h-43.08
          c-3.131,0-5.668,2.538-5.668,5.668v82.907C208.383,145.622,210.92,148.16,214.051,148.16z"
        />
      </g>
    </g>
  </svg>
)

export const Zoom: React.FC<IconProps> = ({
  strokeColor = colors.slateDark,
  ...rest
}) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <title>Zoom Icon</title>
    <circle
      cx="9.43472"
      cy="9.43482"
      r="6.64664"
      stroke={strokeColor}
      strokeWidth="1.5"
    />
    <path
      d="M14.5557 14.5555l3.4138 3.4139"
      stroke={strokeColor}
      strokeWidth="1.5"
    />
    <path
      d="M9.43481 5.98093v6.90777M5.98096 9.43481h6.90784"
      stroke={strokeColor}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
)

export const Filter: React.FC<IconProps> = ({
  strokeColor = colors.slateDark,
  ...rest
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64.34 65.85" {...rest}>
    <g id="objkt">
      <g>
        <path d="m55.86,65.85c-.61,0-1.1-.49-1.1-1.1V1.1c0-.61.49-1.1,1.1-1.1s1.1.49,1.1,1.1v63.66c0,.61-.49,1.1-1.1,1.1Z" />
        <path d="m63.24,25.11h-14.76c-.61,0-1.1-.49-1.1-1.1s.49-1.1,1.1-1.1h14.76c.61,0,1.1.49,1.1,1.1s-.49,1.1-1.1,1.1Z" />
      </g>
      <g>
        <path d="m8.48,65.85c-.61,0-1.1-.49-1.1-1.1V1.1c0-.61.49-1.1,1.1-1.1s1.1.49,1.1,1.1v63.66c0,.61-.49,1.1-1.1,1.1Z" />
        <path d="m15.86,14.88H1.1c-.61,0-1.1-.49-1.1-1.1s.49-1.1,1.1-1.1h14.76c.61,0,1.1.49,1.1,1.1s-.49,1.1-1.1,1.1Z" />
      </g>
      <g>
        <path d="m32.17,65.85c-.61,0-1.1-.49-1.1-1.1V1.1c0-.61.49-1.1,1.1-1.1s1.1.49,1.1,1.1v63.66c0,.61-.49,1.1-1.1,1.1Z" />
        <path d="m39.55,53.17h-14.77c-.61,0-1.1-.49-1.1-1.1s.49-1.1,1.1-1.1h14.77c.61,0,1.1.49,1.1,1.1s-.49,1.1-1.1,1.1Z" />
      </g>
    </g>
  </svg>
)

export const Edit: React.FC<IconProps> = ({
  strokeColor = colors.slateDark,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 64.1 65.85"
    {...rest}
  >
    <defs>
      <style>{".cls-1{stroke-width:0}"}</style>
    </defs>
    <path
      d="M50.84 51.93c-.1 0-.2-.01-.29-.04l-20.46-5.67c-.38-.1-.67-.4-.77-.78-.1-.38 0-.78.29-1.05l14.8-14.79c.27-.27.68-.38 1.06-.29.38.1.67.39.78.77l5.67 20.46a1.097 1.097 0 0 1-1.06 1.39Zm-18.36-7.32 16.78 4.65-4.65-16.78-12.13 12.13Z"
      className="cls-1"
    />
    <path
      d="M30.38 46.27c-.28 0-.56-.11-.78-.32L.32 16.67c-.21-.21-.32-.48-.32-.78s.12-.57.32-.78L15.12.32c.43-.43 1.12-.43 1.55 0L45.95 29.6c.21.21.32.48.32.78s-.12.57-.32.78l-14.8 14.79c-.21.21-.5.32-.78.32ZM2.65 15.89l27.73 27.73 13.24-13.24L15.89 2.65 2.65 15.89Z"
      className="cls-1"
    />
    <path
      d="M63 65.85H1.1c-.61 0-1.1-.49-1.1-1.1V44.58c0-.61.49-1.1 1.1-1.1s1.1.49 1.1 1.1v19.07h59.71V3.96H42.84c-.61 0-1.1-.49-1.1-1.1s.49-1.1 1.1-1.1h20.17c.61 0 1.1.49 1.1 1.1v61.9c0 .61-.49 1.1-1.1 1.1Z"
      className="cls-1"
    />
  </svg>
)
