import React from "react"
import { Field } from "formik"
import TextInput from "@components/inputs/TextInput"
import { hookOnChange } from "../utils"

const TextField = ({
  name,
  type = "text",
  label,
  placeholder,
  onChange = (...args: any) => {},
  autoComplete = "off",
  className,
  disabled,
}: any) => (
  <Field name={name}>
    {({ field, form }) => {
      const touched = form.touched && form.touched[name]
      const error = form.errors && form.errors[name]

      return (
        <TextInput
          {...field}
          type={type}
          label={label}
          placeholder={placeholder}
          onChange={hookOnChange(field, onChange)}
          error={!!touched && !!error}
          helperText={touched && error ? error : undefined}
          autoComplete={autoComplete}
          className={className}
          disabled={!!disabled}
        />
      )
    }}
  </Field>
)

export default TextField
