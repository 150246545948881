import React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"
import Link from "next/link"
import colors from "@constants/colors"
import { body, h5, h6 } from "@constants/typography"
import VerticalSpace from "@components/VerticalSpace"
import Button from "@components/buttons/Button"

const Container = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%;
  padding-bottom: 58px;

  ${Button} {
    justify-content: flex-start;
    padding: 0 16px;
  }

  ${up("laptop")} {
    padding-bottom: 0;
  }
`

const Content = styled.div`
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  display: flex;

  ${up("laptop")} {
    flex-grow: 0;
    align-items: flex-start;
    transform: translateY(-8vh);
  }
`

const Title = styled.div`
  ${h5}
  text-align: center;
  color: ${colors.obsidian};

  ${up("laptop")} {
    ${h6}
    text-align: left;
    color: ${colors.obsidian};
  }
`

const Body = styled.div`
  ${body}
  text-align: center;
  color: ${colors.slateDark};

  ${up("laptop")} {
    text-align: left;
  }
`

const Footer = styled.div`
  width: 100%;
  margin-top: 40px;

  ${up("laptop")} {
    transform: translateY(-8vh);
    margin-top: 32px;
  }
`

interface VerificationProps {
  onDismissVerification?: React.ReactEventHandler
}

const Verification: React.FC<VerificationProps> = ({
  onDismissVerification,
}) => {
  return (
    <Container>
      <Content>
        <Title>Almost done!</Title>
        <VerticalSpace mobileHeight={24} height={34} />
        <Body>
          We sent a verification link to your email. Please click the link when
          you get a chance.
        </Body>
      </Content>
      <Footer>
        {onDismissVerification ? (
          <Button onClick={onDismissVerification}>Ok</Button>
        ) : (
          <Link href="/account" passHref>
            <a>
              <Button>Ok</Button>
            </a>
          </Link>
        )}
      </Footer>
    </Container>
  )
}

export default Verification
