import styled, { css } from "styled-components"
import colors from "@constants/colors"
import { timings, cubicBezier } from "@constants/animation"

export default styled.div<{ isOpen?: boolean; topOffset?: number }>`
  ${({ isOpen, topOffset }) => css`
    z-index: 1001;
    position: absolute;
    top: ${topOffset || 0}px;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    display: none !important;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    will-change: background-color;
    transition: background-color ${timings.quick} ${cubicBezier},
      visibility 0ms linear ${timings.medium};
    width: 100vw;
    height: 100vh;
    background-color: transparent;

    ${isOpen &&
    css`
      display: block !important;
      opacity: 1;
      transition: background-color ${timings.quick} ${cubicBezier},
        visibility 0ms linear;
      background-color: ${colors.divider50};
    `}
  `}
`
