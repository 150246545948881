import React from "react"
import styled, { css } from "styled-components"
import colors from "@constants/colors"
import { bodyBold } from "@constants/typography"

const Container = styled.div<{ count: number }>`
  ${({ count }) => css`
    ${bodyBold}
    position: fixed;
    align-items: center;
    justify-content: center;
    display: ${count > 0 ? "inline-flex" : "none"};
    transform: translateX(10px) translateY(-14px);
    min-width: 14px;
    height: 14px;
    padding: 0 3px;
    border-radius: 50% 50% 50% 0;
    background-color: ${colors.primary};
    font-size: 9px;
    text-transform: uppercase;
    color: ${colors.snow};
  `}
`

export interface CountBubbleProps {
  count: number
}

const CountBubble: React.FC<CountBubbleProps> = ({
  count,
}: CountBubbleProps) => {
  return <Container count={count}>{count}</Container>
}

export default CountBubble
