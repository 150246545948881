import React from "react"
import styled, { css } from "styled-components"
import { up } from "styled-breakpoints"
import Link from "next/link"
import colors from "@constants/colors"
import { body, subtextBold } from "@constants/typography"
import { UserOutline } from "@components/icons"
import { IconWrapper } from "@components/icons/utils"
import VerticalSpace from "@components/VerticalSpace"
import {
  FooterProps,
  SocialIcons,
  BoldLink,
  Smallcaps,
  Warning,
  WarningLink,
  WarningLinkRedText,
} from "./common"

const Container = styled.div`
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  width: 100%;
  padding: 60px 28px 0 32px;

  ${up("laptop")} {
    display: none;
  }
`

const Row = styled.div`
  align-items: center;
  display: flex;
`

const Account = styled.div`
  justify-content: space-between;
  display: flex;
  width: 100%;
  margin-bottom: 36px;
`

const AccountLink = styled.a`
  ${body}
  display: flex;
  font-size: 14px;
  color: ${colors.snow};
`

const Address = styled.address`
  flex-direction: column;
  display: flex;
`

const AddressText = styled.div`
  ${subtextBold};
  opacity: 0.9;
  color: ${colors.snow};
`

const fadedLinkStyles = css`
  ${body}
  align-items: center;
  display: flex;
  opacity: 0.9;
  height: 48px;
  margin-bottom: 4px;
  color: ${colors.snow};
`

const FadedLink = styled.a`
  ${fadedLinkStyles}
`

const FadedLinkExternal = styled.a`
  ${fadedLinkStyles}
`

const StyledWarning = styled(Warning)`
  ${up("laptop")} {
    display: none;
  }
`

const MobileFooter: React.FC<FooterProps> = ({
  user,
  contactPhoneNumber,
  contactAddress,
  facebookUrl,
  instagramUrl,
  youtubeUrl,
  mobileFooterLinks,
  privacyPolicy,
  copyrightText,
  prop65Disclaimer,
  prop65Link,
  onShopByVehicleMobile,
  onShopProSeriesMobile,
  onShopAllProductsMobile,
}) => (
  <>
    <Container>
      <Account>
        <Link href="/account" passHref>
          <AccountLink>
            <IconWrapper style={{ marginRight: "6px" }}>
              <UserOutline strokeColor={colors.snow} />
            </IconWrapper>
            {user ? user : "Account"}
          </AccountLink>
        </Link>
      </Account>
      <BoldLink onClick={onShopByVehicleMobile}>Shop by Vehicle</BoldLink>
      <BoldLink onClick={onShopProSeriesMobile}>Pro Series</BoldLink>
      <BoldLink onClick={onShopAllProductsMobile}>All Products</BoldLink>
      <BoldLink onClick={() => {}}>
        <Link href={"/configurator"}>Configurator</Link>
      </BoldLink>
      <VerticalSpace height={28} />
      {mobileFooterLinks.map(({ label, url, external }) =>
        external ? (
          <FadedLinkExternal key={url} href={url} target="_blank">
            {label}
          </FadedLinkExternal>
        ) : (
          <Link key={url} href={url} passHref>
            <FadedLink>{label}</FadedLink>
          </Link>
        )
      )}
      <VerticalSpace height={36} />
      <Address>
        <Row>
          <AddressText as="a" href={`tel:${contactPhoneNumber}`}>
            {contactPhoneNumber}
          </AddressText>
        </Row>
        <VerticalSpace height={10} />
        <AddressText>{contactAddress}</AddressText>
      </Address>
      <VerticalSpace height={48} />
      <SocialIcons
        facebookUrl={facebookUrl}
        instagramUrl={instagramUrl}
        youtubeUrl={youtubeUrl}
      />
      <VerticalSpace height={52} />
      <Smallcaps as="a" href={privacyPolicy} target="_blank">
        Privacy Policy
      </Smallcaps>
      <VerticalSpace height={10} />
      <Smallcaps>{copyrightText}</Smallcaps>
      <VerticalSpace height={16} />
    </Container>
    <StyledWarning>
      <WarningLink href={prop65Link} target="_blank">
        <span>&#9888;</span> {prop65Disclaimer}{" "}
        <WarningLinkRedText>{prop65Link}</WarningLinkRedText>
      </WarningLink>
    </StyledWarning>
  </>
)

export default MobileFooter
