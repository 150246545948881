import Button from "@components/buttons/Button"
import PlainButton from "@components/buttons/PlainButton"
import { IconWrapper } from "@components/icons/utils"
import { cubicBezier, timings } from "@constants/animation"
import colors from "@constants/colors"
import { bodyBold, h6 } from "@constants/typography"
import useGtmEvents from "@hooks/useGtmEvents"
import { ToastProduct } from "@redux/types"
import React from "react"
import { up } from "styled-breakpoints"
import styled, { css } from "styled-components"
import Backdrop from "../Backdrop"
import { Close, ColorCheckmark } from "../icons"

const StyledBackdrop = styled(Backdrop)`
  display: none;

  ${up("laptop")} {
    display: initial;
  }
`

const openProps = css`
  z-index: 1002;
  opacity: 1;
  transform: translateY(0);
  transition: opacity ${timings.quick} ${cubicBezier},
    transform ${timings.medium} ${cubicBezier};
  pointer-events: initial;

  ${up("laptop")} {
    transform: translateY(0);
  }
`

const Container = styled.div<{
  isOpen?: boolean
  xPosition?: string
  yPosition?: string
}>`
  ${({ isOpen, xPosition, yPosition }) => css`
    z-index: -1;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    opacity: 0;
    transform: translateY(-100%);
    transition: opacity ${timings.medium} ${cubicBezier},
      transform ${timings.quick} ${cubicBezier};
    will-change: opacity, transform;
    background-color: ${colors.snow};
    pointer-events: none;
    cursor: default;

    ${up("laptop")} {
      top: ${yPosition || "62px"};
      right: ${xPosition || "calc(42px + 106px + 24px - 30px)"};
      transform: translateY(20%);
      width: 376px;
    }

    ${isOpen && openProps}
  `}
`

const Diamond = styled.div`
  display: none;

  ${up("laptop")} {
    position: absolute;
    top: -6px;
    right: 22px;
    display: initial;
    transform: rotate(-45deg);
    width: 16px;
    height: 16px;
    background-color: ${colors.snow};
  }
`

const Header = styled.button`
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 72px;
  width: 100%;
  padding: 0 16px 0 24px;
  outline: none;
  border: none;
  border-bottom: solid 1px ${colors.divider};
  background: none;

  &:focus,
  &:active {
    outline: none;
  }

  ${up("laptop")} {
    padding-right: 20px;
  }
`

const HeaderText = styled.div`
  ${h6}
  flex-grow: 1;
  padding-left: 16px;
  text-align: left;
  color: ${colors.obsidian};
`

const CircularIcon = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  width: 24px;
  height: 24px;
  padding: 2.5px;
  border-radius: 50%;
  background-color: ${colors.obsidian};
`

const Body = styled.div`
  padding: 32px 20px 42px;
  background-color: ${colors.silver20};

  ${up("laptop")} {
    padding: 32px 24px 42px;
  }
`

const Product = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-bottom: 32px;
`

const ImageContainer = styled.div`
  position: relative;
  flex-shrink: 0;
  align-self: flex-start;
  overflow: hidden;
  width: 64px;
  height: 64px;
  margin-right: 22px;
`

const Image = styled.img`
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
`

const Details = styled.div`
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
`

const Name = styled.div`
  ${bodyBold}
  padding-right: 20px;
  color: ${colors.slateDark};
`

const Buttons = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
`

const CartButtonWrapper = styled.div`
  width: calc(50% - 10px);
`

const CheckoutButtonWrapper = styled.a<{ hasLink?: boolean }>`
  ${({ hasLink }) => css`
    display: flex;
    width: calc(50% - 10px);
    pointer-events: ${hasLink ? "initial" : "none"};
    cursor: ${hasLink ? "pointer" : "progress"};
  `}
`

export interface CartToastProps {
  isOpen: boolean
  product: ToastProduct | null
  cartCount: number
  checkoutLink: string
  xPosition?: string
  yPosition?: string
  checkoutId: string | undefined
  items: Array<any> | []
  subtotal: string | undefined
  total: string | undefined
  onClickCart: () => void
  onRequestClose: () => void
}

const CartToast: React.FC<CartToastProps> = ({
  isOpen,
  product,
  cartCount,
  checkoutLink,
  xPosition,
  yPosition,
  checkoutId,
  items,
  subtotal,
  total,
  onClickCart,
  onRequestClose,
}) => {
  const toastRef: any = React.useRef(null)

  const { onCheckout: onCheckoutGtmEvent } = useGtmEvents()
  const fireGTMcheckout = () => {
    // onCheckoutGtmEvent(checkoutId, items, subtotal, total)
  }

  const handleOnRequestClose = (event) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    onRequestClose()
    onClickCart()
  }

  const disableCheckoutLink = !checkoutLink || !checkoutLink.length

  return (
    <>
      <StyledBackdrop isOpen={isOpen} />
      <Container
        ref={toastRef}
        isOpen={isOpen}
        xPosition={xPosition}
        yPosition={yPosition}
      >
        <Header onClick={onRequestClose}>
          <Diamond />
          <CircularIcon>
            <ColorCheckmark strokeColor={colors.snow} />
          </CircularIcon>
          <HeaderText>Added to Cart!</HeaderText>
          <IconWrapper>
            <Close strokeColor={colors.obsidian} />
          </IconWrapper>
        </Header>
        <Body>
          {product && (
            <Product>
              <ImageContainer>
                <Image src={product.image} />
              </ImageContainer>
              <Details>
                <Name>{product.productName}</Name>
              </Details>
            </Product>
          )}
          <Buttons>
            <CartButtonWrapper>
              <PlainButton onClick={handleOnRequestClose}>
                {`View Cart (${cartCount})`}
              </PlainButton>
            </CartButtonWrapper>
            {disableCheckoutLink ? (
              <CheckoutButtonWrapper>
                <Button id="toastCheckout" onClick={fireGTMcheckout}>
                  Checkout
                </Button>
              </CheckoutButtonWrapper>
            ) : (
              <CheckoutButtonWrapper href={checkoutLink} hasLink>
                <Button id="toastCheckout" onClick={fireGTMcheckout}>
                  Checkout
                </Button>
              </CheckoutButtonWrapper>
            )}
          </Buttons>
        </Body>
      </Container>
    </>
  )
}

export default CartToast
