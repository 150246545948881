import React from "react"
import styled, { css } from "styled-components"
import colors from "@constants/colors"
import { body, subtext } from "@constants/typography"
import { Chevron } from "@components/icons"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import InputLabel from "@material-ui/core/InputLabel"
import NativeSelect, { NativeSelectProps } from "@material-ui/core/NativeSelect"

export const Container = styled.div<{ error?: boolean }>`
  ${({ error }) => css`
    width: 100%;
    height: 52px;
    padding: 0 16px;
    border-radius: 2px;
    background-color: ${error ? colors.error05 : colors.lightestGray};

    .MuiFormControl-root {
      width: 100%;
    }

    .MuiNativeSelect-select {
      height: 20px;
    }

    .MuiNativeSelect-select:focus {
      background-color: transparent;
    }

    .MuiInputBase-root {
      ${body}
      height: 20px;
      margin-top: 24px;
      color: ${error ? colors.error : colors.obsidian};
    }

    .MuiInput-underline:before {
      border: none;
    }

    && .MuiInput-underline:hover:before {
      border: none;
    }

    .MuiInput-underline:after {
      border: none;
    }

    .MuiInputLabel-root {
      ${body}
      opacity: 1;
      transform: translate(0, 16px) scale(1);
      color: ${error ? colors.error : colors.slateDark};
      pointer-events: none;
    }

    && .MuiInputLabel-shrink {
      ${body}
      transform: translate(0, 6px) scale(0.8);
      color: ${error ? colors.error50 : colors.slateDark};

      &.Mui-focused {
        color: ${error ? colors.error50 : colors.slateDark};
      }
    }

    .MuiFormHelperText-root {
      ${subtext}
      margin-top: 10px;
      color: ${error ? colors.error : colors.slateDark};
    }
  `}
`

const Option = styled.option``

const IconWrapper = styled.div`
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  transform: translateY(-8px);
  height: 21px;
  width: 21px;
`

const IconComponent = (props) => (
  <IconWrapper {...props}>
    <Chevron />
  </IconWrapper>
)

interface SelectInputProps extends NativeSelectProps {
  name: string
  label: string
  options: Array<{
    label: string
    value: string | number
    disabled?: boolean
  }>
  error?: boolean
  helperText?: string
}

const SelectInput: React.FC<SelectInputProps> = ({
  name,
  label,
  value,
  options,
  error,
  helperText,
  onChange,
}) => {
  return (
    <Container error={error}>
      <FormControl>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <NativeSelect
          id={name}
          value={value}
          onChange={onChange}
          inputProps={{ name }}
          IconComponent={IconComponent}
        >
          <Option aria-label="None" value="" />
          {options.map(({ label, value, disabled }, i) => (
            <Option key={i} value={value} disabled={disabled}>
              {label}
            </Option>
          ))}
        </NativeSelect>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Container>
  )
}

export default SelectInput
