import React from "react"
import Layout from "../index"
import _ from "lodash"

const withContentfulData: any = (WrappedComponent: any) => (props: any) => {
  const layoutData = require("@data/layoutData")

  return <WrappedComponent {...props} layoutData={layoutData} />
}

export default withContentfulData(Layout) as any
