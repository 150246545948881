import React from "react"
import Head from "next/head"

interface HelmetProps {
  title: string
  description?: string
  meta?: React.ReactNode
}

const Helmet: React.FC<HelmetProps> = ({ title, description = "", meta }) => {
  return (
    <Head>
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <link rel="icon" href="/favicon.svg" />
      <link rel="shortcut icon" href="/favicon.svg" />
      <title>{title}</title>
      <meta name="description" content={description || ""} />
      {meta}

      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="anonymous"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600&display=swap"
        rel="stylesheet"
      ></link>
    </Head>
  )
}

export default Helmet
