import React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"
import colors from "@constants/colors"
import Item, { ItemProps } from "./Item"
import { LaptopUpOnly } from "@utils/MediaQueries"

const Container = styled.div`
  padding: 0 20px;
  background-color: ${colors.silver20};

  ${up("laptop")} {
    flex-direction: column;
    flex-grow: 1;
    display: flex;
    overflow: hidden;
    overflow-y: auto;
    height: 100%;
    padding: 32px 64px 0;
    background-color: transparent;
  }
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.divider};
`

interface BodyProps {
  items: Array<ItemProps>
}

const Body: React.FC<BodyProps> = ({ items }) => {
  return (
    <Container>
      {items.map((item, i) => (
        <React.Fragment key={i}>
          <Item {...item} />
          {i !== items.length - 1 && <Divider />}
        </React.Fragment>
      ))}
      <LaptopUpOnly>
        <Divider />
      </LaptopUpOnly>
    </Container>
  )
}

export default Body
