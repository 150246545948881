import React from "react"
import styled from "styled-components"
import Link from "next/link"
import colors from "@constants/colors"
import { body } from "@constants/typography"
import { UserOutline } from "@components/icons"
import { IconWrapper } from "@components/icons/utils"

const Container = styled.a`
  ${body}
  align-items: center;
  justify-content: flex-start;
  display: flex;
  height: 88px;
  margin-top: auto;
  padding: 0 16px;
  border-top: solid 1px ${colors.divider};
  font-size: 14px;

  ${IconWrapper} {
    margin-right: 8px;
  }
`

interface FooterProps {
  user?: string
  onRequestClose?: () => void
}

const Footer: React.FC<FooterProps> = ({ user, onRequestClose }) => <></>

export default Footer
