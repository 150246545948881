// import "slick-carousel/slick/slick.css"
// import "slick-carousel/slick/slick-theme.css"
import React from "react"
import Slider from "react-slick"
import styled, { css } from "styled-components"
import { up } from "styled-breakpoints"
import Link from "next/link"
import colors from "@constants/colors"
import { timings, cubicBezier } from "@constants/animation"
import { body, subtext, h6 } from "@constants/typography"
import { Arrow, Chevron } from "@components/icons"
import { IconWrapper } from "@components/icons/utils"
import Button from "@components/buttons/Button"
import { PromoBannerProps } from "./common"
import useModalBackgroundClose from "@hooks/useModalBackgroundClose"

const baseButtonStyles = css`
  border: none;
  background-color: transparent;
  outline: none;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  &:active {
    outline: none;
  }
`

const disabledStyles = css`
  visibility: hidden;
  pointer-events: none;
`

const Container = styled.div`
  display: none;
  height: 36px;

  ${up("laptop")} {
    display: initial;
  }
`

const ExpandedContainer = styled.div<{ expanded: boolean }>`
  ${({ expanded }) => css`
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(${expanded ? "0" : "-100%"});
    transition: transform ${timings.medium} ${cubicBezier};
    width: 100%;
    border-bottom: solid 1px ${colors.divider};
    background-color: ${colors.snow};
  `}
`

const ExpandedContentRow = styled.div`
  display: flex;
`

const ExpandedPrevButton = styled.button<{ disabled: boolean }>`
  ${({ disabled }) => css`
    ${baseButtonStyles}
    width: calc(2 / 12 * 100vw);
    margin-right: calc(1 / 12 * 100vw);
    padding-left: calc(1 / 12 * 100vw);

    ${disabled && disabledStyles}
  `}
`

const ExpandedNextButton = styled.button`
  ${({ disabled }) => css`
    ${baseButtonStyles}
    width: calc(2 / 12 * 100vw);
    margin-left: calc(1 / 12 * 100vw);
    padding-right: calc(1 / 12 * 100vw);

    ${disabled && disabledStyles}
  `}
`

const ExpandedPrevArrow = styled(IconWrapper)``

const ExpandedNextArrow = styled(ExpandedPrevArrow)`
  transform: rotate(180deg);
`

const ExpandedSliderWrapper = styled.div`
  width: calc(6 / 12 * 100vw);
  overflow: hidden;
`

const ExpandedPromoWrapper = styled.div`
  padding-bottom: 80px;

  &:focus {
    outline: none;
  }

  &:active {
    outline: none;
  }

  ${Button} {
    width: auto;
    padding: 0 24px;
  }
`

const ExpandedPromo = styled.div`
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  width: 100%;
  padding-top: 62px;
`

const ExpandedHeader = styled.div`
  ${h6}
`

const ExpandedBody = styled.div`
  ${body};
  margin: 42px 0;
  color: ${colors.slateDark};

  p {
    margin: 0 0 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    margin: 0 0 20px;
    padding-left: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const ExternalLink = styled.a``

const CollapseButton = styled.button`
  ${baseButtonStyles}
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 78px;
`

const CollapseIconWrapper = styled(IconWrapper)`
  transform: rotate(180deg);
  width: 36px;
  height: 36px;
`

const Content = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
  height: 36px;
  padding-right: 20px;
  background-color: ${colors.red};
`

const Spacer = styled.div`
  flex-grow: 1;
  display: flex;
`

const Links = styled.div`
  flex-grow: 1;
  align-items: center;
  text-align: end;

  a {
    font-family: "Jost", sans-serif;
    font-size: 14px;
    margin-left: 25px;
    color: ${colors.snow};
    text-transform: uppercase;
  }
`

const BannerSliderColumn = styled.div`
  position: absolute;
  left: 50%;
  display: flex;
  transform: translateX(-50%);

  [class*="BannerPrevButton"] {
    svg {
      path {
        stroke: #fff;
      }
    }
  }
`

const BannerSliderWrapper = styled.div`
  width: 601px;
  height: 36px;
  overflow: hidden;

  .slick-track {
    height: 36px;
    overflow: hidden;
  }
`

const BannerPromoWrapper = styled.div``

const BannerPromo = styled.button`
  ${baseButtonStyles}
  align-items: center;
  display: flex;
  width: 100%;
  height: 36px;
  padding: 0 16px;
  border-radius: 2px;
`

const BannerPromoHeader = styled.div`
  font-family: "Jost", sans-serif;
  font-size: 14px;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${colors.snow};
`

const BannerPrevButton = styled.button<{ disabled: boolean }>`
  ${({ disabled }) => css`
    ${baseButtonStyles}
    align-items: center;
    justify-content: center;
    display: flex;
    transform: rotate(90deg);
    width: 36px;
    height: 36px;
    padding: 6px;

    ${disabled && disabledStyles}
  `}
`

const BannerNextButton = styled(BannerPrevButton)`
  transform: rotate(270deg);
`

const DesktopPromoBanner: React.FC<PromoBannerProps> = ({
  promoBannerLinks,
  promos,
}) => {
  const [expanded, setExpanded] = React.useState<boolean>(false)

  const expandBody = (e) => {
    e.stopPropagation()
    setExpanded(true)
  }

  const collapseBody = () => {
    setExpanded(false)
  }

  const bannerSliderRef = React.useRef<any>(null)
  const expandedSliderRef = React.useRef<any>(null)

  const onPrevSlide = () => {
    if (
      bannerSliderRef &&
      bannerSliderRef.current &&
      expandedSliderRef &&
      expandedSliderRef.current
    ) {
      bannerSliderRef.current.slickPrev()
      expandedSliderRef.current.slickPrev()
    }
  }

  const onNextSlide = () => {
    if (
      bannerSliderRef &&
      bannerSliderRef.current &&
      expandedSliderRef &&
      expandedSliderRef.current
    ) {
      bannerSliderRef.current.slickNext()
      expandedSliderRef.current.slickNext()
    }
  }

  const expandedContainerRef: any = React.useRef(null)

  useModalBackgroundClose({
    modalRef: expandedContainerRef,
    isOpen: expanded,
    onRequestClose: collapseBody,
  })

  return (
    <Container>
      <ExpandedContainer ref={expandedContainerRef} expanded={expanded}>
        <ExpandedContentRow>
          <ExpandedPrevButton
            onClick={onPrevSlide}
            disabled={promos.length < 2}
          >
            <ExpandedPrevArrow>
              <Arrow />
            </ExpandedPrevArrow>
          </ExpandedPrevButton>
          <ExpandedSliderWrapper>
            <Slider
              dots={false}
              arrows={false}
              draggable={false}
              infinite
              ref={expandedSliderRef}
            >
              {promos.map(
                ({ header, body, link, externalLink, buttonText }, i) => (
                  <ExpandedPromoWrapper key={`expanded-${i}-${header}`}>
                    <ExpandedPromo>
                      <ExpandedHeader>{header}</ExpandedHeader>
                      <ExpandedBody
                        dangerouslySetInnerHTML={{
                          __html: body,
                        }}
                      />
                      {link && (
                        <Link href={link!} passHref>
                          <Button>{buttonText || "Go"}</Button>
                        </Link>
                      )}
                      {externalLink && (
                        <ExternalLink
                          href={externalLink}
                          target="_blank"
                          rel="noopener"
                        >
                          <Button>{buttonText || "Go"}</Button>
                        </ExternalLink>
                      )}
                    </ExpandedPromo>
                  </ExpandedPromoWrapper>
                )
              )}
            </Slider>
          </ExpandedSliderWrapper>
          <ExpandedNextButton
            onClick={onNextSlide}
            disabled={promos.length < 2}
          >
            <ExpandedNextArrow>
              <Arrow />
            </ExpandedNextArrow>
          </ExpandedNextButton>
        </ExpandedContentRow>
        <CollapseButton onClick={collapseBody}>
          <CollapseIconWrapper>
            <Chevron strokeColor={colors.slateDark} />
          </CollapseIconWrapper>
        </CollapseButton>
      </ExpandedContainer>
      <Content>
        <Spacer />
        <BannerSliderColumn>
          <BannerPrevButton onClick={onPrevSlide} disabled={promos.length < 2}>
            <Chevron strokeColor={colors.slateMid} />
          </BannerPrevButton>
          <BannerSliderWrapper>
            <Slider
              dots={false}
              arrows={false}
              autoplay={true}
              autoplaySpeed={5000}
              draggable={false}
              infinite
              ref={bannerSliderRef}
            >
              {promos.map(({ header }, i) => (
                <BannerPromoWrapper key={`banner-${i}-${header}`}>
                  <BannerPromo onClick={expandBody}>
                    <BannerPromoHeader>{header}</BannerPromoHeader>
                  </BannerPromo>
                </BannerPromoWrapper>
              ))}
            </Slider>
          </BannerSliderWrapper>
          <BannerNextButton onClick={onNextSlide} disabled={promos.length < 2}>
            <Chevron strokeColor={colors.slateMid} />
          </BannerNextButton>
        </BannerSliderColumn>
        <Links>
          {promoBannerLinks.map(({ label, link }) => (
            <Link key={link} href={link} passHref>
              {label}
            </Link>
          ))}
        </Links>
      </Content>
    </Container>
  )
}

export default DesktopPromoBanner
