import React from "react"
import _ from "lodash"

const ADD_TO_CART = "addToCart"
const CHECKOUT = "checkout"
const VIEW_COLLECTION = "viewCollection"
const VIEW_PRODUCT_DETAILS = "viewProductDetails"

export interface GTMProductProps {
  name?: string
  productName: string
  slug: string
  sku?: string
  price?: string
  quantity?: number
  make?: string
  model?: string
  year?: string
  trim?: string
  category?: string
  subcategory?: string
  seatcategory?: string
  productCategory?: string
  productSubategory?: string
}

export interface GTMCollectionProps {
  make?: string
  model?: string
  year?: string
  trim?: string
  category?: string
  subcategory?: string
  seatcategory?: string
}

const getDataLayer = () => {
  const _window = typeof window !== "undefined" && (window as any)
  if (_window) {
    return _window.dataLayer || []
  }
}

const formatProduct = (product: GTMProductProps) => {
  return _.omitBy(product, (p) => !p)
}

const formatCollection = (collection: GTMCollectionProps) => {
  return _.omitBy(collection, (p) => !p)
}

const useGtmEvents = () => {
  const dataLayer = getDataLayer()

  const onAddToCart = React.useCallback(
    (products: Array<GTMProductProps>, checkoutId) => {
      const orderId = checkoutId ? checkoutId.split("key=")[1] : undefined

      if (dataLayer) {
        const formattedProducts = products.map(formatProduct)

        const subtotal = formattedProducts.reduce((total: any, item: any) => {
          total = total + item.quantity * Number.parseFloat(item.price)
          return total
        }, 0) as any

        const [product, upsells] = _.partition(
          formattedProducts,
          (p) => p.slug === formattedProducts[0].slug
        )

        dataLayer.push({ ecommerce: null })

        products.map((p) => {
          dataLayer.push({
            event: ADD_TO_CART,
            ecommerce: {
              add: {
                products: {
                  productName: p.productName, // Name or ID is required.
                  price: p.price,
                  make: p.make,
                  model: p.model,
                  category: p.category,
                  subcategory: p.subcategory,
                  seatcategory: p.seatcategory,
                  quantity: p.quantity,
                },
              },
              product: {
                productName: p.productName, // Name or ID is required.
                price: p.price,
                make: p.make,
                model: p.model,
                category: p.category,
                subcategory: p.subcategory,
                seatcategory: p.seatcategory,
                quantity: p.quantity,
              },
              items: [
                {
                  item_name: p.productName,
                  item_id: p.sku,
                  price: p.price,
                  item_brand: "TMI",
                  item_category: p.category,
                  quantity: 1,
                },
              ],
            },
            ord: (orderId || checkoutId).replace(/[^a-z0-9]/gi, ""),
            u4: p.productName, // Name or ID is required.
            u5: p.price,
            cost: p.price,
            u10: p.make,
            u7: p.model,
            u20: p.model,
            u21: p.seatcategory,
            u11: p.category,
            u12: p.subcategory,
            u18: p.category,
            u19: p.subcategory,
            u6: p.quantity,
            Item: p.productName, // Name or ID is required.
            Price: p.price,
            Make: p.make,
            productModel: p.model,
            productCategory: p.category,
            productSubCategory: p.subcategory,
            productSeatCategory: p.seatcategory,
            Qty: p.quantity,
          })

          dataLayer.push({ ecommerce: null })

          //GA4
          dataLayer.push({
            event: "add_to_cart",
            currency: "USD",
            value: p.price,
            items: [
              {
                item_name: p.productName,
                item_id: p.sku,
                price: p.price,
                item_brand: "TMI",
                item_category: p.category,
                quantity: 1,
              },
            ],
            ecommerce: {
              currency: "USD",
              product: {
                productName: p.productName,
                price: p.price,
              },
              items: [
                {
                  item_name: p.productName,
                  item_id: p.sku,
                  price: p.price,
                  item_brand: "TMI",
                  item_category: p.category,
                  quantity: 1,
                },
              ],
            },
          })
        })

        //PSantos - old version
        /*
        dataLayer.push({
          event: ADD_TO_CART,
          ecommerce: {
            add: {
              products: [
                {
                  name: product[0].productName, // Name or ID is required.
                  price: product[0].price,
                  brand: product[0].make,
                  category: product[0].model,
                  variant: product[0].subcategory,
                },
              ],
            },
          },
        })
        */

        // console.log(dataLayer)

        /*
        dataLayer.push({
          ecommerce: {
            product,
            upsells,
            subtotal,
          },
          event: ADD_TO_CART,
        })
        */
      }
    },
    [dataLayer]
  )

  //Added checkout event - Psantos
  const onCheckout = React.useCallback(
    (checkoutId, items, subtotal, total) => {
      //Get cart items
      // const formattedItems = items.map((item) => {
      //   return {
      //     name: item.productName,
      //     price: item.price,
      //     quantity: item.quantity,
      //     brand: item.productName,
      //     category: item.productName,
      //     variant: item.variantName,
      //   }
      // })
      if (dataLayer) {
        const ga4Items = items.map((item) => {
          return {
            item_id: item.productName,
            item_name: item.productName,
            price: item.price,
            quantity: item.quantity,
            item_brand: "TMI",
            item_variant: item.variantName,
          }
        })

        //build datalayer object and fire event
        // dataLayer.push({
        //   event: CHECKOUT,
        //   transactionId: checkoutId,
        //   transactionAffiliation: "TMI Website Checkout",
        //   transactionTotal: total,
        //   transactionProducts: formattedItems,
        // })
        dataLayer.push({
          event: "begin_checkout",
          currency: "USD",
          value: total,
          items: ga4Items,
        })
      }
    },
    [dataLayer]
  )

  const onViewCollection = React.useCallback(
    (collection: GTMCollectionProps) => {
      if (dataLayer) {
        dataLayer.push({
          collection: formatCollection(collection),
          event: VIEW_COLLECTION,
        })
      }
    },
    [dataLayer]
  )

  const onViewProductDetails = React.useCallback(
    (product: GTMProductProps) => {
      if (dataLayer) {
        dataLayer.push({
          product,
          event: VIEW_PRODUCT_DETAILS,
        })

        dataLayer.push({ ecommerce: null })

        //GA4
        dataLayer.push({
          event: "view_item",
          currency: "USD",
          value: product.price,
          items: [
            {
              item_name: product.productName,
              item_id: product.sku,
              price: product.price,
              item_brand: "TMI",
              item_category: product.category,
            },
          ],
          ecommerce: {
            currency: "USD",
            value: product.price,
            items: [
              {
                item_name: product.productName,
                item_id: product.sku,
                price: product.price,
                item_brand: "TMI",
                item_category: product.category,
              },
            ],
          },
        })
      }
    },
    [dataLayer]
  )

  return {
    onAddToCart,
    onViewCollection,
    onViewProductDetails,
    onCheckout,
  }
}

export default useGtmEvents
