import React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"
import Link from "next/link"
import { Image } from "react-datocms"
import colors from "@constants/colors"
import { bodyBold } from "@constants/typography"
import { MobileOnly, LaptopUpOnly } from "@utils/MediaQueries"
import { DatoResponsiveImage } from "@dato/types"

export const Container = styled.a`
  flex-direction: column;
  display: flex;
  padding-bottom: 24px;
  background-color: ${colors.grayCard};

  ${up("laptop")} {
    background-color: ${colors.divider};
  }
`

export const ImageWrapper = styled.div`
  padding: 40px 48px 20px;

  ${up("laptop")} {
    padding: 36px 44px 26px;
  }

  .DatoImage {
    height: auto;

    div {
      opacity: 0 !important;
      padding-bottom: 100%;

      ${up("laptop")} {
        padding-bottom: 120%;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center center;
    }
  }
`

const Title = styled.div`
  ${bodyBold}
  width: 100%;
  text-align: center;
  color: ${colors.slateDark};

  ${up("laptop")} {
    color: ${colors.obsidian};
  }
`

export interface CategoryCardProps {
  url: string
  mobileImage: DatoResponsiveImage
  image: DatoResponsiveImage
  title: string
  onClick?: () => void
}

const MenuCard: React.FC<CategoryCardProps> = ({
  url,
  mobileImage,
  image,
  title,
  onClick,
}) => {
  return (
    <Link href={url} passHref>
      <Container onClick={onClick}>
        <ImageWrapper>
          <MobileOnly>
            <Image
              data={{
                ...(mobileImage ? mobileImage : image),
                bgColor: "transparent",
              }}
              className="DatoImage"
            />
          </MobileOnly>
          <LaptopUpOnly>
            <Image
              data={{ ...image, bgColor: "transparent" }}
              className="DatoImage"
            />
          </LaptopUpOnly>
        </ImageWrapper>
        <Title>{title}</Title>
      </Container>
    </Link>
  )
}

export default MenuCard
