import React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"
import colors from "@constants/colors"
import MobileFooter from "./MobileFooter"
import DesktopFooter from "./DesktopFooter"
import { FooterProps } from "./common"

const Container = styled.footer`
  flex-direction: column;
  justify-content: center;
  display: flex;
  width: 100%;
  background-color: ${colors.obsidian};

  ${up("laptop")} {
    flex-direction: row;
  }
`

const Footer: React.FC<FooterProps> = (props) => {
  return (
    <Container>
      <DesktopFooter {...props} />
      <MobileFooter {...props} />
    </Container>
  )
}

export default Footer
