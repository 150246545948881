import styled, { css } from "styled-components"
import { up } from "styled-breakpoints"
import colors from "@constants/colors"
import measurements from "@constants/measurements"
import { bodyBold } from "@constants/typography"

export default styled.button<{ disabled?: boolean; mobile?: boolean }>`
  ${({ disabled, mobile }) => css`
    ${bodyBold}
    align-items: center;
    justify-content: center;
    display: flex;
    width: ${mobile ? "37.5%" : "100%"};
    height: ${measurements.BUTTON_HEIGHT};
    outline: none;
    border: none;
    border-radius: 2px;
    background: ${disabled ? colors.divider : colors.primary};
    text-align: center;
    color: ${disabled ? colors.grayDisabled : colors.snow};

    @media (min-width: 500px) {
      max-height: 60px;
      padding: auto;
    }

    ${up("laptop")} {
      &:hover {
        cursor: pointer;
      }
    }
  `}
`
