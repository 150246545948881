export const DESKTOP_PROMO_HEIGHT = "36px"
export const DESKTOP_HEADER_HEIGHT = "80px"
export const DESKTOP_HERO_HEIGHT = "calc(100vw * 0.4)"
export const MOBILE_HEADER_HEIGHT = "60px"
export const MOBILE_HERO_HEIGHT = `calc(100vh - ${MOBILE_HEADER_HEIGHT} - 52px - 24px)` // 52px nav bar, 24px white space
export const BUTTON_HEIGHT = "52px"
export const LINK_BUTTON_HEIGHT = "44px"
export const SQUARE_BUTTON_DIMENSIONS = "44px"
export const ICON_DIMENSIONS = "20px"
export const MAX_WIDTH = "1440px"

export default {
  DESKTOP_HEADER_HEIGHT,
  MOBILE_HEADER_HEIGHT,
  DESKTOP_HERO_HEIGHT,
  MOBILE_HERO_HEIGHT,
  BUTTON_HEIGHT,
  LINK_BUTTON_HEIGHT,
  SQUARE_BUTTON_DIMENSIONS,
  ICON_DIMENSIONS,
  MAX_WIDTH,
}
