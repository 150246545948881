import React from "react"
import _ from "lodash"

const useModalBackgroundClose = ({
  modalRef,
  excludeRefs,
  isOpen,
  onRequestClose,
  allowScroll,
}: any) => {
  const handleClick = React.useCallback(
    (event: any) => {
      let preventClose = false

      if (onRequestClose && excludeRefs) {
        excludeRefs.forEach((ref) => {
          if (ref.current.contains(event.target)) {
            preventClose = true
          }
        })
      }

      const body = document.getElementsByTagName("body")[0]

      if (!body.contains(event.target)) {
        preventClose = true
      }

      if (
        !preventClose &&
        onRequestClose &&
        modalRef &&
        !modalRef.current.contains(event.target)
      ) {
        onRequestClose()
      }
    },
    [modalRef, excludeRefs, onRequestClose]
  )

  const hasOpenModal = (className) =>
    className && _.includes(className, "modalClassRef")

  React.useEffect((): any => {
    if (modalRef && isOpen) {
      const body = document.getElementsByTagName("body")[0]
      const [refClass] = modalRef.current.classList
      const refLabel = `modalClassRef-${refClass}`

      !allowScroll && body.classList.add("noScroll")
      body.classList.add(refLabel)
      document.addEventListener("click", handleClick)

      return () => {
        document.removeEventListener("click", handleClick)
        body.classList.remove(refLabel)

        if (
          !allowScroll &&
          _.filter(body.classList, hasOpenModal).length === 0
        ) {
          body.classList.remove("noScroll")
        }
      }
    }
  }, [isOpen, modalRef, excludeRefs])
}

export default useModalBackgroundClose
