import React from "react"
import styled, { css } from "styled-components"
import colors from "@constants/colors"
import { timings, cubicBezier } from "@constants/animation"
import Header from "./Header"
import Item from "./Item"
import VerticalSpace from "@components/VerticalSpace"

const openProps = css`
  transform: translateX(0);
  transition: transform ${timings.medium} ${cubicBezier};
`
const Container = styled.div<{ isOpen?: boolean }>`
  ${({ isOpen }) => css`
    z-index: 3;
    position: absolute;
    top: 0;
    opacity: 1;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    transform: translateX(100%);
    transition: transform ${timings.medium} ${cubicBezier};
    will-change: transform;
    width: 100%;
    height: 100%;
    background-color: ${colors.snow};
    cursor: default;

    ${isOpen ? openProps : ""}
  `}
`

const overlayActiveProps = css`
  opacity: 0.1;
  transition: opacity ${timings.medium} ${cubicBezier};
`
const Overlay = styled.div<{ isVisible: boolean }>`
  ${({ isVisible }) => css`
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity ${timings.long} ${cubicBezier};
    will-change: opacity;
    height: 100%;
    width: 100%;
    background-color: ${colors.black};
    pointer-events: none;

    ${isVisible ? overlayActiveProps : ""}
  `}
`

interface ProductsProps {
  isOpen: boolean
  mainSectionLabel: string
  mainSectionLinks: Array<{
    label: string
    link: string
  }>
  sideSectionLabel: string
  sideSectionLinks: Array<{
    label: string
    link: string
  }>
  onRequestBack?: () => void
  onRequestClose: () => void
}

enum MenuStates {
  Primary = "primary",
  Secondary = "secondary",
}

const Products: React.FC<ProductsProps> = ({
  isOpen,
  mainSectionLabel,
  mainSectionLinks,
  sideSectionLabel,
  sideSectionLinks,
  onRequestBack,
  onRequestClose,
}) => {
  const [menuState, setMenuState] = React.useState<MenuStates | undefined>(
    undefined
  )

  const clearMenuState = () => {
    setMenuState(undefined)
  }

  return (
    <>
      <Overlay isVisible={isOpen} />
      <Container isOpen={isOpen}>
        <Header
          label={
            menuState === MenuStates.Primary
              ? mainSectionLabel
              : menuState === MenuStates.Secondary
              ? sideSectionLabel
              : "All Products"
          }
          onRequestBack={
            menuState === undefined ? onRequestBack : clearMenuState
          }
          onRequestClose={onRequestClose}
        />
        <VerticalSpace height={8} />
        {menuState === MenuStates.Primary &&
          mainSectionLinks.map((item, i) => (
            <Item key={i} {...item} onClick={onRequestClose} />
          ))}
        {menuState === MenuStates.Secondary &&
          sideSectionLinks.map((item, i) => (
            <Item key={i} {...item} onClick={onRequestClose} />
          ))}
        {menuState === undefined && (
          <>
            <Item
              label={mainSectionLabel}
              onClick={() => {
                setMenuState(MenuStates.Primary)
              }}
            />
            <Item
              label={sideSectionLabel}
              onClick={() => {
                setMenuState(MenuStates.Secondary)
              }}
            />
          </>
        )}
      </Container>
    </>
  )
}

export default Products
