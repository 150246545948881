import React from "react"
import styled, { css } from "styled-components"
import { up } from "styled-breakpoints"
import colors from "@constants/colors"
import { columns } from "@constants/layout"
import { body, h4 } from "@constants/typography"
import { Chevron, Close } from "@components/icons"
import { MobileOnly, LaptopUpOnly } from "@utils/MediaQueries"

const Container = styled.div<{
  hideHeaderOnMobile?: boolean
  extraWide?: boolean
}>`
  ${({ hideHeaderOnMobile, extraWide }) => css`
    display: ${hideHeaderOnMobile ? "none" : "inherit"};
    padding: 0 20px 0 10px;
    border-bottom: solid 1px ${colors.divider};

    ${up("laptop")} {
      display: inherit;
      padding: ${extraWide ? columns(0.5) : columns(1)};
      padding-bottom: 0;
      border: none;
    }
  `}
`

const Content = styled.div`
  ${body}
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 100%;
  min-height: 88px;
  font-weight: 600;
  line-height: 24px;
  color: ${colors.obsidian};

  ${up("laptop")} {
    ${h4}
    min-height: initial;
    border: none;
    color: ${colors.black};
  }
`

const Button = styled.button<{ extraWide?: boolean; mobileClose?: boolean }>`
  ${({ extraWide, mobileClose }) => css`
    align-items: center;
    display: flex;
    width: 100%;
    padding: 0;
    border: none;
    background-color: transparent;
    justify-content: ${mobileClose ? "space-between" : "normal"};

    &:hover {
      cursor: pointer;
    }

    &:focus,
    &:active {
      outline: none;
    }

    ${up("laptop")} {
      z-index: 10;
      position: fixed;
      top: ${extraWide ? columns(0.5) : columns(1)};
      right: ${extraWide ? columns(0.5) : columns(1)};
      align-items: center;
      justify-content: center;
      display: flex;
      width: 44px;
      height: 44px;
      border-radius: 2px;
      background-color: ${colors.divider};
    }
  `}
`

const HeaderWrapper = styled.div`
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
`

const Header = styled.div`
  text-align: left;
`

const SubHeader = styled.div`
  color: ${colors.slateDark};
  font-weight: normal;
  line-height: 20px;

  ${up("laptop")} {
    ${body}
    margin-top: 16px;
    color: ${colors.slateDark};
  }
`

const IconWrapper = styled.div`
  transform: rotate(90deg);
  width: 24px;
  height: 24px;

  ${up("laptop")} {
    display: flex;
    transform: rotate(0);
    width: 26px;
    height: 26px;
  }
`

interface ModalProps {
  mobileHeader?: string
  mobileClose?: boolean
  header?: string
  subheader?: string
  ctaComponent?: React.ReactNode
  headerComponent?: React.ReactNode
  hideHeaderOnMobile?: boolean
  hideHeaderOnDesktop?: boolean
  extraWide?: boolean
  onClose?: React.ReactEventHandler
}

const Modal: React.FC<ModalProps> = ({
  mobileHeader,
  mobileClose = false,
  header,
  subheader,
  ctaComponent,
  headerComponent,
  hideHeaderOnMobile,
  hideHeaderOnDesktop,
  extraWide,
  onClose,
}) => {
  return (
    <Container hideHeaderOnMobile={hideHeaderOnMobile} extraWide={extraWide}>
      <Content>
        <MobileOnly style={{ width: "100%" }}>
          <Button onClick={onClose} mobileClose={mobileClose}>
            {mobileClose ? (
              ""
            ) : (
              <IconWrapper>
                <Chevron />
              </IconWrapper>
            )}
            {headerComponent ? (
              headerComponent
            ) : (
              <HeaderWrapper>
                <Header>{mobileHeader || header || ""}</Header>
                {subheader && <SubHeader>{subheader}</SubHeader>}
                {ctaComponent && ctaComponent}
              </HeaderWrapper>
            )}
            {mobileClose ? (
              <IconWrapper>
                <Close />
              </IconWrapper>
            ) : (
              ""
            )}
          </Button>
        </MobileOnly>
        <LaptopUpOnly>
          <Button extraWide={extraWide} onClick={onClose}>
            <IconWrapper>
              <Close />
            </IconWrapper>
          </Button>
          {!hideHeaderOnDesktop &&
            (headerComponent ? (
              headerComponent
            ) : (
              <HeaderWrapper>
                <Header>{header || ""}</Header>
                {subheader && <SubHeader>{subheader}</SubHeader>}
                {ctaComponent && ctaComponent}
              </HeaderWrapper>
            ))}
        </LaptopUpOnly>
      </Content>
    </Container>
  )
}

export default Modal
