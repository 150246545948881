import React from "react"
import styled, { css } from "styled-components"
import { up } from "styled-breakpoints"
import colors from "@constants/colors"
import { body } from "@constants/typography"
import { Close, MagnifyingGlass } from "@components/icons"
import { IconWrapper } from "@components/icons/utils"
import { MobileOnly, LaptopUpOnly } from "@utils/MediaQueries"

const Container = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  height: 52px;
  padding-left: 16px;
  border-radius: 2px;
  background-color: ${colors.lightestGray};
`

const InputWrapper = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`

const placeholderStyles = css`
  opacity: 1;
  line-height: normal !important;
  color: ${colors.slateDark};
`

const Input = styled.input`
  ${body}
  width: 100%;
  height: 52px;
  padding: 0 14px;
  border: none;
  background-color: transparent;
  color: ${colors.obsidian};

  &:focus,
  &:active {
    outline: none;
  }

  ::-webkit-input-placeholder {
    ${placeholderStyles}
  }
  ::-moz-placeholder {
    ${placeholderStyles}
  }
  :-ms-input-placeholder {
    ${placeholderStyles}
  }
  :-moz-placeholder {
    ${placeholderStyles}
  }

  ${up("laptop")} {
    padding-right: 0;
  }
`

const Button = styled.button`
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 52px;
  width: 56px;
  padding: 0;
  background-color: ${colors.snow};
  border: none;

  &:focus {
    outline: none;
  }

  ${up("laptop")} {
    background-color: transparent;

    &:hover {
      cursor: pointer;
    }
  }
`

interface SearchInputProps {
  inputRef?: any
  value: string
  placeholder?: string
  onChange?: (event: any) => void
  onKeyDown?: (event: any) => void
  onRequestClear?: () => void
}

const SearchInput: React.FC<SearchInputProps> = ({
  inputRef,
  value,
  placeholder,
  onChange,
  onKeyDown,
  onRequestClear,
}) => {
  return (
    <Container>
      <IconWrapper>
        <MagnifyingGlass strokeColor={colors.obsidian} />
      </IconWrapper>
      <InputWrapper>
        <Input
          ref={inputRef}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          placeholder={placeholder || "Search"}
        />
      </InputWrapper>
      <Button onClick={onRequestClear}>
        <MobileOnly>
          <IconWrapper style={{ width: "32px", height: "32px" }}>
            <Close strokeColor={colors.obsidian} strokeWidth="1" />
          </IconWrapper>
        </MobileOnly>
        <LaptopUpOnly>
          <IconWrapper>
            <Close strokeColor={colors.slateLight} />
          </IconWrapper>
        </LaptopUpOnly>
      </Button>
    </Container>
  )
}

export default SearchInput
