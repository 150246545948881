import { connect } from "react-redux"
import CartToast from "../index"
import { Dispatch, RootState } from "@redux/store"
import { selectCartQuantity, selectCheckoutLink } from "@redux/selectors"
import { isNilOrEmpty } from "@utils/index"

interface ownProps {
  xPosition?: string
  yPosition?: string
  onClickCart: () => void
}

const mapState = (state: RootState, ownProps: ownProps) => {
  return {
    isOpen: !isNilOrEmpty(state.checkout.toastProduct),
    product: state.checkout.toastProduct,
    cartCount: selectCartQuantity(state),
    checkoutLink: selectCheckoutLink(state),
  }
}

const mapDispatch = (dispatch: Dispatch) => ({
  onRequestClose: () => {
    dispatch.checkout.updateToastProduct(null)
  },
})

export default connect(mapState, mapDispatch)(CartToast)
