import React from "react"
import styled, { css } from "styled-components"
import { up } from "styled-breakpoints"

const Container = styled.div<{
  height?: number
  heightString?: string
  mobileHeight?: number
  mobileHeightString?: string
}>`
  ${({ height, heightString, mobileHeight, mobileHeightString }) => css`
    height: ${mobileHeight || mobileHeightString
      ? mobileHeightString
        ? mobileHeightString
        : `${mobileHeight}px`
      : heightString
      ? heightString
      : `${height || 0}px`};

    ${up("laptop")} {
      height: ${heightString ? heightString : `${height || 0}px`};
    }
  `}
`

interface VerticalSpaceProps {
  height?: number
  heightString?: string
  mobileHeight?: number
  mobileHeightString?: string
}

const VerticalSpace: React.FC<VerticalSpaceProps> = ({
  height,
  heightString,
  mobileHeight,
  mobileHeightString,
}) => {
  return (
    <Container
      height={height}
      heightString={heightString}
      mobileHeight={mobileHeight}
      mobileHeightString={mobileHeightString}
    />
  )
}

export default VerticalSpace
