import React from "react"
import styled, { css } from "styled-components"
import { up, down } from "styled-breakpoints"
import colors from "@constants/colors"
import Button from "@components/buttons/Button"
import PlainButton from "@components/buttons/PlainButton"
import TextField from "@components/form/TextField"
import SelectInput, {
  Container as SelectInputContainer,
} from "@components/inputs/SelectInput"
import { Formik, Form } from "formik"
import Modal from "@components/Modal"
import NewsletterSchema, {
  NewsletterFormValues,
  initialValues as defaultValues,
} from "./schema"
import _ from "lodash"
import axios from "axios"
import { Close } from "@components/icons"

const NewsletterModal = styled(Modal)`
  width: fit-content !important;
  background: none;

  [class*="Modal__ModalBody"] {
    padding: 0;

    p.intent {
      text-align: center;
      font-family: "Jost", sans-serif;
      color: ${colors.snow};
      margin-top: 30px;
    }

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-track {
      background: #000;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #555;
      outline: 1px solid #555;
      border-radius: 2px;
    }

    [class*="VerticalSpace__Container"] {
      display: none;
    }
  }

  ${down("tablet")} {
    top: 20px;
    bottom: 20px;
    left: 20px;
    right: 20px;
    width: auto !important;
    height: auto;
  }
`

const Container = styled.div`
  background: url("/newsletter-bg.jpg");
  background-position: center center;
  background-size: cover;
  max-width: 600px;
  padding: 0 40px;

  ${up("tablet")} {
    padding: 0 6.25vw;
  }

  ${down("tablet")} {
    max-width: 100%;
    padding: 0 40px;
  }

  form {
    position: relative;
    width: 100%;

    &:active {
      outline: none;
    }

    &:focus {
      outline: none;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus {
      box-shadow: 0 0 0 1000px ${colors.lightestGray} inset !important;
      color: ${colors.obsidian} !important;
    }
  }

  fieldset {
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    display: flex;
    max-width: 100% !important;

    margin-bottom: 24px !important;

    .hs-form-field {
      width: 100%;
    }

    &.form-columns-2 {
      .hs-form-field {
        flex-grow: 0;
        width: calc(50% - 12px);
      }
    }

    ${up("laptop")} {
      flex-direction: row;
    }

    label {
      font-family: Inter, Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 20px;
      transform: translate(0px, 6px) scale(0.8);
      color: rgb(50, 57, 62);
      padding: 8px 16px 0px;
    }

    .input {
      padding-left: 8px;
    }

    input,
    select,
    textarea {
      font-family: Inter, Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      line-height: 20px;
      color: rgb(4, 12, 12);
      padding: 8px;
      border: 0;
      height: 1.1876em;
      margin: 0;
      display: block;
      min-width: 0;
      background: none;
      box-sizing: content-box;
      animation-name: mui-auto-fill-cancel;
      letter-spacing: inherit;
      animation-duration: 10ms;
      -webkit-tap-highlight-color: transparent;
      width: 100%;
      outline: 0;
    }

    textarea {
      resize: none;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        display: block;

        &.hs-form-booleancheckbox {
          label {
            display: flex;
            padding: 8px;
            transform: none;

            input {
              margin-right: 10px;
              font-size: 24px;
              width: 24px;
              height: 24px;
            }

            span {
              font-size: 15px;
            }
          }
        }
      }

      .hs-error-msg {
        font-family: Inter, Helvetica, Arial, sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
        margin-top: 12px;
        padding-left: 16px;
        color: rgb(245, 61, 61);
      }
    }
  }

  .hs_error_rollup {
    .hs-error-msgs {
      margin: 0;
      padding: 0;
      margin-bottom: 20px;
    }
    li {
      display: block;

      .hs-main-font-element {
        font-family: Inter, Helvetica, Arial, sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
        margin-top: 12px;
        padding-left: 16px;
        color: rgb(245, 61, 61);
      }
    }
  }

  .hs_submit .actions {
    text-align: center;

    .hs-button {
      font-family: Inter, Helvetica, Arial, sans-serif;
      font-style: normal;
      font-size: 15px;
      line-height: 20px;
      font-weight: 550;
      align-items: center;
      justify-content: center;
      display: flex;
      width: 100%;
      height: 52px;
      outline: none;
      border: none;
      border-radius: 2px;
      background: rgb(236, 28, 35);
      text-align: center;
      color: rgb(255, 255, 255);

      &:disabled {
        background: rgba(0, 0, 0, 0.05);
        color: rgba(118, 118, 118, 0.5);
      }
    }
  }
`
const ModalTitle = styled.div`
  text-align: center;
  font-family: "Jost", sans-serif;
  color: ${colors.snow};
  font-size: 50px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 40px;

  span {
    font-size: 60px;
  }

  ${down("tablet")} {
    font-size: 40px;

    span {
      font-size: 50px;
    }
  }
`

const ImageContainer = styled.div`
  text-align: center;
  margin-bottom: 10px;
`

const Image = styled.img`
  width: 150px;
  object-fit: cover;
  object-position: center center;
`

const Title = styled.div`
  margin: 10px auto;
  color: ${colors.snow};
  text-align: center;
  font-family: "Jost", sans-serif;
  text-transform: uppercase;
  font-size: 16px;
`

const FieldRow = styled.div`
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  display: flex;

  ${up("tablet")} {
    flex-direction: row;
  }
`

const CloseWrapper = styled.div`
  position: absolute;
  top: 40px;
  right: 40px;
  display: inline-block;
  ${down("tablet")} {
    top: 20px;
    right: 20px;
  }
`

const IconWrapper = styled.div`
  transform: rotate(90deg);
  width: 32px;
  height: 32px;

  ${up("tablet")} {
    display: flex;
    transform: rotate(0);
    width: 40px;
    height: 40px;
  }
`

//Psantos edited
const FieldWrapper = styled.div<{ halfWidth?: boolean; thirdWidth?: boolean }>`
  ${({ halfWidth, thirdWidth }) => css`
    width: 100%;
    margin-bottom: 24px;

    ${up("laptop")} {
      flex-grow: 0;
      width: ${halfWidth
        ? "calc(50% - 12px)"
        : thirdWidth
        ? "calc(30% - 12px)"
        : "100%"};
    }
  `}

  .MuiFormControl-root {
    height: 40px;
    background: ${colors.lightestGray};
  }

  [class*="SelectInput__Container"] {
    height: 40px;

    label + .MuiInput-formControl {
      margin-top: 16px;
    }

    .MuiNativeSelect-icon {
      top: calc(50% - 8px);
    }
  }

  .MuiInputLabel-root {
    transform: translate(0, 10px) scale(1);
    font-family: "Jost", sans-serif;
    font-weight: 600;
  }

  .MuiFormLabel-root {
    font-size: 14px;
    &.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-shrink {
      font-size: 12px;
      transform: translate(0, 3px) scale(0.8);
      font-weight: 300;

      &.Mui-error {
        color: #f44336;
      }
    }
  }

  .MuiInput-input {
    font-size: 13px;
  }

  label + .MuiInput-formControl {
    margin-top: 10px;
  }
`
const Filter = styled.div<{ disabled?: boolean }>`
  ${({ disabled }) => css`
    pointer-events: ${disabled ? "none" : "all"};

    [class*="SelectInput__Container"] {
      background-color: ${disabled ? colors.mediumGray : colors.lightestGray};

      .MuiFormControl-root {
        background-color: ${disabled ? colors.mediumGray : colors.lightestGray};
      }
    }
    pointer-events: ${disabled ? "none" : "all"};

    [class*="MuiTextField-root"] {
      background-color: ${disabled ? colors.mediumGray : colors.lightestGray};
    }

    .MuiInput-underline.Mui-disabled:before {
      display: none;
    }
  `}
`

//Copied logic from

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;

  ${up("laptop")} {
    display: block;
    width: 200px;
    margin: 0 auto;
    padding-bottom: 50px;
  }
`

const SubmitButton = styled.div`
  flex-grow: 0;

  button[type="submit"] {
    background: #ff0000;
    font-family: "Jost", sans-serif;
    text-transform: uppercase;
    color: ${colors.snow};
  }

  ${up("laptop")} {
    width: 100%;
  }

  ${down("tablet")} {
    width: calc(50% - 10px);
  }
`

const CancelButton = styled(SubmitButton)`
  flex-grow: 0;
  width: calc(50% - 10px);

  ${up("laptop")} {
    display: none;
  }
`

export interface NewsletterFormProps {
  formRef?: any
  isOpen: boolean
  title: string
  initialValues?: NewsletterFormValues
  onClose: () => void
  onSubmit: (values) => void
}

const NewsletterForm: React.FC<NewsletterFormProps> = ({
  formRef,
  isOpen,
  title,
  initialValues,
  onSubmit,
  onClose,
}) => {
  const [show, setShow] = React.useState(isOpen)
  const [formData, setFormData] = React.useState<NewsletterFormValues>({
    ...(initialValues || defaultValues),
  })

  const handleSubmit = async (values) => {
    console.log(values)
    //Evaluate YMM

    console.log(values)
    const hubspotFields = [
      {
        objectTypeId: "0-1",
        name: "firstname",
        value: values.firstName,
      },
      {
        objectTypeId: "0-1",
        name: "lastname",
        value: values.lastName,
      },
      {
        objectTypeId: "0-1",
        name: "email",
        value: values.email,
      },
      {
        objectTypeId: "0-1",
        name: "make",
        value: make == "other" ? otherMake : make,
      },
      {
        objectTypeId: "0-1",
        name: "model",
        value: model == "other" ? otherModel : model,
      },
      {
        objectTypeId: "0-1",
        name: "year",
        value: values.year,
      },
    ]

    const hubspotData = {
      fields: hubspotFields,
      context: {
        pageUri: "https://www.tmiproducts.com/",
        pageName: "Newsletter Form",
      },
      // ...(values.subscribe
      //   ? {
      //       legalConsentOptions: {
      //         consent: {
      //           // Include this object when GDPR options are enabled
      //           consentToProcess: true,
      //           text: "Check here to sign up for the latest TMI news, promotions, and events.",
      //           communications: [
      //             {
      //               value: true,
      //               subscriptionTypeId: 45040449,
      //               text: "Check here to sign up for the latest TMI news, promotions, and events.",
      //             },
      //           ],
      //         },
      //       },
      //     }
      //   : undefined),
    }

    try {
      await axios
        .post(
          process.env.NEXT_PUBLIC_HUBSPOT_NEWSLETTER_FORM_ENDPOINT!,
          hubspotData
        )
        .then(function (response) {
          let expiryDate = new Date(Date.now() + 90 * (1000 * 60 * 60 * 24))
          document.cookie =
            "newsletter_submitted" +
            "=true; expires=" +
            expiryDate.toUTCString()
          console.log(response)
        })
    } catch (error) {
      console.error(error)
    }

    await onSubmit(values)
    onClose()
  }
  const layoutData = require("@data/layoutData")
  const cars = layoutData.allCars

  const [make, setMake] = React.useState<string | "">("")
  const [otherMake, setOtherMake] = React.useState<string | "">("")
  const [model, setModel] = React.useState<string | "">("")
  const [otherModel, setOtherModel] = React.useState<string | "">("")
  const [trim, setTrim] = React.useState<string | "">("")

  React.useEffect(() => {
    if (isOpen) {
      let expiryDate = new Date(Date.now() + 14 * (1000 * 60 * 60 * 24))

      document.cookie =
        "modal_seen" + "=true; expires=" + expiryDate.toUTCString()
    }
    setShow(isOpen)
  }, [isOpen])

  const makes = [
    ...(_.sortBy(
      _.uniqBy(
        cars.map(({ make }) => ({ label: make.name, value: make.slug })).sort(),
        "value"
      ),
      ["value"]
    ) as Array<any>),
    { label: "Other", value: "other" },
  ]

  const [models, setModels] = React.useState<Array<any>>([])
  const [trims, setTrims] = React.useState<Array<any>>([])

  const onSelectMake = (make) => {
    const filteredModels = [
      ..._.sortBy(
        _.uniqBy(
          cars
            .filter((car) => car.make.slug === make)
            .map(({ model }) => ({ label: model.name, value: model.slug })),
          "value"
        ),
        ["value"]
      ),
      { label: "Other", value: "other" },
    ]

    setMake(make)
    setModels(filteredModels)

    setMake(make)
    setModel("")
    setTrim("")
    setTrims([])
  }

  const onSelectModel = (model) => {
    if (model.trim() != "") {
      const filteredCars = _.sortBy(
        cars.filter(
          (car) => car.make.slug === make && car.model.slug === model
        ),
        ["slug"]
      )

      if (filteredCars.length > 1) {
        const trims = _.sortBy(
          _.uniqBy(
            filteredCars.map(({ trim }) => ({
              label: trim.name,
              value: trim.slug,
            })) as any,
            "value"
          ),
          ["value"]
        ) as any

        setModel(model)
        setTrim("")
        setTrims(trims)
      } else {
        setModel(model)
        setTrim("")
        setTrims([])
      }
    } else {
      setModel("")
      setTrims([])
    }
  }

  const onSelectTrim = (trim) => {
    const thisCar = cars.find((car) => {
      return (
        car.make.slug === make &&
        car.model.slug === model &&
        car.trim.slug === trim
      )
    })

    if (thisCar) {
      const filteredYears = thisCar.years.split(", ").map((year) => ({
        label: year.trim(),
        value: year.trim(),
      }))

      setTrim(trim)
    }
  }

  return (
    <NewsletterModal isOpen={show} backdropRef={formRef} onClose={onClose}>
      <CloseWrapper>
        <IconWrapper>
          <Close
            strokeColor={colors.snow}
            width="40px"
            height="40px"
            onClick={onClose}
          />
        </IconWrapper>
      </CloseWrapper>
      <Formik
        validationSchema={NewsletterSchema}
        isInitialValid={false}
        initialValues={formData}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          const { isValid, isSubmitting } = formik

          return (
            <Container>
              <ImageContainer>
                <Image src="/favicon.svg" title="Location" />
              </ImageContainer>
              <ModalTitle>
                <span>[</span>Sign Up<span>]</span>
              </ModalTitle>

              <p className="intent">
                Sign up for our newsletter where you will be the first to hear
                about promotions, new product, and the latest TMI news.
              </p>

              <Form>
                <br />
                <Title>About you</Title>
                <FieldRow>
                  <FieldWrapper>
                    <TextField
                      name="email"
                      placeholder="Email (*)"
                      label="Email"
                    />
                  </FieldWrapper>
                </FieldRow>
                <FieldRow>
                  <FieldWrapper halfWidth>
                    <TextField
                      name="firstName"
                      placeholder="First Name"
                      label="First Name"
                    />
                  </FieldWrapper>
                  <FieldWrapper halfWidth>
                    <TextField
                      name="lastName"
                      placeholder="Last Name"
                      label="Last Name"
                    />
                  </FieldWrapper>
                </FieldRow>
                <Title>About your car</Title>
                <FieldRow>
                  <FieldWrapper halfWidth={make == "other"}>
                    <SelectInput
                      name="Make"
                      label="Make"
                      value={make}
                      options={makes}
                      onChange={(e) => {
                        onSelectMake(e.target.value)
                      }}
                    />
                  </FieldWrapper>
                  {make == "other" ? (
                    <FieldWrapper halfWidth>
                      <TextField
                        name="MakeOther"
                        placeholder="Make"
                        label="Make"
                      />
                    </FieldWrapper>
                  ) : (
                    <></>
                  )}
                </FieldRow>
                <FieldRow>
                  {make != "other" ? (
                    <FieldWrapper halfWidth={model == "other"}>
                      <Filter disabled={!make}>
                        <SelectInput
                          name={"Model"}
                          label={"Model"}
                          value={make == "other" ? "Other" : model}
                          options={models}
                          onChange={(e) => {
                            onSelectModel(e.target.value)
                          }}
                        />
                      </Filter>
                    </FieldWrapper>
                  ) : (
                    <></>
                  )}
                  {make == "other" || model == "other" ? (
                    <FieldWrapper halfWidth={model == "other"}>
                      <TextField
                        name="ModelOther"
                        placeholder="Model"
                        label="Model"
                      />
                    </FieldWrapper>
                  ) : (
                    <></>
                  )}
                </FieldRow>
                <FieldRow>
                  <FieldWrapper>
                    <Filter
                      disabled={
                        (!make || !model) && (!otherMake || !otherModel)
                      }
                    >
                      <TextField
                        name="year"
                        placeholder="Year"
                        label="Year"
                        disabled={
                          (!make || !model) && (!otherMake || !otherModel)
                        }
                      />
                    </Filter>
                  </FieldWrapper>
                </FieldRow>
                <ButtonWrapper>
                  <CancelButton>
                    <PlainButton onClick={onClose}>Cancel</PlainButton>
                  </CancelButton>
                  <SubmitButton>
                    <Button type="submit" disabled={!isValid || isSubmitting}>
                      Sign Up!
                    </Button>
                  </SubmitButton>
                </ButtonWrapper>
              </Form>
            </Container>
          )
        }}
      </Formik>
    </NewsletterModal>
  )
}

export default NewsletterForm
