import { connect } from "react-redux"
import Cart from "../index"
import { Dispatch, RootState } from "@redux/store"
import { selectCartQuantity, selectCheckoutLink } from "@redux/selectors"

interface ownProps {
  isOpen: boolean
  onRequestClose: () => void
}

const mapState = (state: RootState, ownProps: ownProps) => {
  return {
    showSignIn: !!state.customer.customerAccessToken,
    items: state.checkout.localCheckout?.items || [],
    subtotal: state.checkout.localCheckout?.subtotal || "",
    total: state.checkout.localCheckout?.total || "",
    cartCount: selectCartQuantity(state),
    checkoutId: state.checkout.checkoutId,
    checkoutLink: selectCheckoutLink(state),
  }
}

const mapDispatch = (dispatch: Dispatch) => ({
  onUpdateCartItem: dispatch.checkout.updateCart,
  onUpdateDiscount: dispatch.checkout.updateDiscount,
  onRemoveDiscount: () => {
    dispatch.checkout.updateDiscount(undefined)
  },
})

export default (connect(mapState, mapDispatch) as any)(Cart)
