export const formatCurrency = (
  value: number,
  currencyCode: string,
  locale: string
) => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currencyCode,
  }).format(value)
}
