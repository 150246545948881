import React from "react"
import styled, { css } from "styled-components"
import colors from "@constants/colors"
import { body, subtext } from "@constants/typography"
import TextField, { TextFieldProps } from "@material-ui/core/TextField"

const StyledTextField = styled(TextField)<any>`
  ${({ error }) => css`
    width: 100%;
    height: 52px;
    border-radius: 2px;
    background-color: ${error ? colors.error05 : colors.lightestGray};

    .MuiInput-input {
      ${body}
      height: 100%;
      padding: 8px 16px 0;
      color: ${error ? colors.error : colors.obsidian};
    }

    .MuiInput-input::placeholder {
      opacity: 0;
    }

    .MuiInput-underline:before {
      border: none;
    }

    && .MuiInput-underline:hover:before {
      border: none;
    }

    .MuiInput-underline:after {
      border: none;
    }

    .MuiInputLabel-root {
      ${body}
      z-index: 1;
      left: 16px;
      opacity: 1;
      transform: translate(0, 16px) scale(1);
      color: ${error ? colors.error : colors.slateDark};
      pointer-events: none;
    }

    && .MuiInputLabel-shrink {
      ${body}
      transform: translate(0, 6px) scale(0.8);
      color: ${error ? colors.error50 : colors.slateDark};

      &.Mui-focused {
        color: ${error ? colors.error50 : colors.slateDark};
      }
    }

    .MuiFormHelperText-root {
      ${subtext}
      margin-top: 12px;
      padding-left: 16px;
      color: ${error ? colors.error : colors.slateDark};
    }
  `}
`

const TextInput = (props: TextFieldProps) => {
  return <StyledTextField {...props} />
}

export default TextInput
