import React from "react"
import styled, { css } from "styled-components"
import { up } from "styled-breakpoints"
import colors from "@constants/colors"
import { timings, cubicBezier } from "@constants/animation"
import Backdrop from "../../Backdrop"
import useModalBackgroundClose from "@hooks/useModalBackgroundClose"

const openProps = css`
  opacity: 1;
  transform: translateX(0);
  transition: opacity ${timings.quick} ${cubicBezier},
    transform ${timings.medium} ${cubicBezier};
`

const Container = styled.div<{
  width?: string
  isOpen?: boolean
  cssProps?: any
}>`
  ${({ width, isOpen, cssProps }) => css`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    display: none;
    opacity: 0;
    transform: translateX(100%);
    transition: opacity ${timings.medium} ${cubicBezier},
      transform ${timings.quick} ${cubicBezier};
    will-change: opacity, transform;
    width: ${width || "444px"};
    background-color: ${colors.snow};
    cursor: default;

    ${isOpen && openProps}

    ${cssProps}

    ${up("laptop")} {
      display: flex;
    }
  `}
`

interface DesktopSlideoutProps {
  width?: string
  isOpen?: boolean
  cssProps?: any
  onRequestClose?: () => void
  children: React.ReactNode
}

const DesktopSlideout: React.FC<DesktopSlideoutProps> = ({
  width,
  isOpen,
  onRequestClose,
  children,
  cssProps,
}) => {
  const containerRef: any = React.useRef(null)

  useModalBackgroundClose({
    modalRef: containerRef,
    isOpen,
    onRequestClose,
  })

  return (
    <Backdrop isOpen={isOpen}>
      <Container
        ref={containerRef}
        width={width}
        isOpen={isOpen}
        cssProps={cssProps}
      >
        {children}
      </Container>
    </Backdrop>
  )
}

export default DesktopSlideout
