import styled from "styled-components"
import { up, down } from "styled-breakpoints"

export const MobileOnly = styled.div`
  ${up("laptop")} {
    display: none;
  }
`

export const LaptopUpOnly = styled.div`
  display: none;

  ${up("laptop")} {
    display: initial;
  }
`

export const TabletDownOnly = styled.div`
  display: none;

  ${down("tabletLarge")} {
    display: initial;
  }
`

export const MaxWidthUpOnly = styled.div`
  display: none;

  ${up("maxWidth")} {
    display: initial;
  }
`
