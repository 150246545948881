import React from "react"
import styled, { css } from "styled-components"
import { up } from "styled-breakpoints"
import colors from "@constants/colors"
import { timings, cubicBezier } from "@constants/animation"
import Backdrop from "@components/Backdrop"
import Header from "./Header"
import Footer from "./Footer"
import Item from "./Item"
import CarSelector from "./CarSelector"
import ProSeries from "./ProSeries"
import Products from "./Products"
import SubMenu from "./SubMenu"
import VerticalSpace from "@components/VerticalSpace"
import useModalBackgroundClose from "@hooks/useModalBackgroundClose"
import { DatoResponsiveImage } from "@dato/types"
import { MobileOnly } from "@utils/MediaQueries"
import Link from "next/link"

const openProps = css`
  opacity: 1;
  transform: translateX(0);
  transition: opacity ${timings.quick} ${cubicBezier},
    transform ${timings.medium} ${cubicBezier};
`
const Container = styled.div<{ isOpen?: boolean }>`
  ${({ isOpen }) => css`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    flex-direction: column;
    display: flex;
    opacity: 0;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    transform: translateX(100%);
    transition: opacity ${timings.medium} ${cubicBezier},
      transform ${timings.quick} ${cubicBezier};
    will-change: opacity, transform;
    width: calc(100% - 40px);
    background-color: ${colors.snow};
    cursor: default;

    ${up("laptop")} {
      display: none;
    }

    ${isOpen ? openProps : ""}
  `}
`

interface MenuMobileProps {
  isOpen?: boolean
  data: {
    proSeriesHeader: string
    proSeriesBody: string
    proSeriesCategories: Array<{
      name: string
      slug: string
    }>
    mainSectionLabel: string
    mainSectionLinks: Array<{
      label: string
      link: string
    }>
    sideSectionLabel: string
    sideSectionLinks: Array<{
      label: string
      link: string
    }>
    footerRightColumnLabel: string
    footerRightColumnLinks: Array<{
      label: string
      link: string
    }>
    footerLeftColumnLabel: string
    footerLeftColumnLinks: Array<{
      label: string
      link: string
    }>
    cars: Array<{
      make: {
        name: string
        slug: string
      }
      model: {
        name: string
        slug: string
        image: {
          responsiveImage: DatoResponsiveImage
        }
        imageWidth?: number
        imageHeight?: number
        mobileImageWidth?: number
        mobileImageHeight?: number
      }
      trim?: {
        name: string
        slug: string
        image: {
          responsiveImage: DatoResponsiveImage
        }
        imageWidth?: number
        imageHeight?: number
        mobileImageWidth?: number
        mobileImageHeight?: number
      }
      years: string
    }>
    makeModelOrder: Array<{
      make: { name: string; slug: string }
      models: Array<{ name: string; slug: string }>
    }>
  }
  menuStateOnOpen: MenuStates | undefined
  onRequestClose: () => void
}

export enum MenuStates {
  Picker = "picker",
  ProSeries = "proSeries",
  Products = "products",
  Explore = "explore",
  Support = "support",
}

const MenuMobile: React.FC<MenuMobileProps> = ({
  isOpen,
  data,
  menuStateOnOpen,
  onRequestClose,
}) => {
  const [menuState, setMenuState] = React.useState<MenuStates | undefined>(
    undefined
  )

  const clearMenuState = () => {
    setMenuState(undefined)
  }

  React.useEffect(() => {
    if (!isOpen) {
      clearMenuState()
    } else {
      if (!!menuStateOnOpen) {
        setMenuState(menuStateOnOpen)
      }
    }
  }, [isOpen])

  const menuRef: any = React.useRef(null)

  useModalBackgroundClose({
    modalRef: menuRef,
    isOpen,
    onRequestClose,
  })

  return (
    <MobileOnly>
      <Backdrop isOpen={isOpen}>
        <Container id="mobileMenu" ref={menuRef} isOpen={isOpen}>
          <Header onRequestClose={onRequestClose} />
          <VerticalSpace height={8} />
          <Item
            header
            label="Shop by Vehicle"
            onClick={() => {
              setMenuState(MenuStates.Picker)
            }}
          />
          <Item
            header
            label="Universal"
            onClick={() => {
              setMenuState(MenuStates.ProSeries)
            }}
          />
          <Item
            header
            label="Configurator"
            onClick={() => {}}
            link={"/configurator"}
          />
          <VerticalSpace height={40} />
          <Item
            label="All Products"
            onClick={() => {
              setMenuState(MenuStates.Products)
            }}
          />
          <Item
            label="Explore"
            onClick={() => {
              setMenuState(MenuStates.Explore)
            }}
          />
          <Item
            label="Support"
            onClick={() => {
              setMenuState(MenuStates.Support)
            }}
          />
          <Footer onRequestClose={onRequestClose} />
          <CarSelector
            isOpen={menuState === MenuStates.Picker}
            cars={data.cars}
            makeModelOrder={data.makeModelOrder}
            onRequestClose={clearMenuState}
            onRequestCloseMenu={onRequestClose}
          />
          <ProSeries
            isOpen={menuState === MenuStates.ProSeries}
            header={data.proSeriesHeader}
            body={data.proSeriesBody}
            items={data.proSeriesCategories}
            onRequestBack={clearMenuState}
            onRequestClose={onRequestClose}
          />
          <Products
            isOpen={menuState === MenuStates.Products}
            mainSectionLabel={data.mainSectionLabel}
            mainSectionLinks={data.mainSectionLinks}
            sideSectionLabel={data.sideSectionLabel}
            sideSectionLinks={data.sideSectionLinks}
            onRequestBack={clearMenuState}
            onRequestClose={onRequestClose}
          />
          <SubMenu
            isOpen={menuState === MenuStates.Explore}
            label={data.footerRightColumnLabel}
            items={data.footerRightColumnLinks}
            onRequestBack={clearMenuState}
            onRequestClose={onRequestClose}
          />
          <SubMenu
            isOpen={menuState === MenuStates.Support}
            label={data.footerLeftColumnLabel}
            items={data.footerLeftColumnLinks}
            onRequestBack={clearMenuState}
            onRequestClose={onRequestClose}
          />
        </Container>
      </Backdrop>
    </MobileOnly>
  )
}

export default MenuMobile
