import React from "react"
import styled, { css } from "styled-components"
import { up } from "styled-breakpoints"
import colors from "@constants/colors"
import { timings, cubicBezier } from "@constants/animation"
import { columns } from "@constants/layout"
import Backdrop from "@components/Backdrop"
import ModalHeader from "./ModalHeader"
import VerticalSpace from "@components/VerticalSpace"
import useModalBackgroundClose from "@hooks/useModalBackgroundClose"

const openProps = css`
  opacity: 1;
  transform: translate(0, 0);
  transition: opacity ${timings.medium} ${cubicBezier},
    transform ${timings.medium} ${cubicBezier};

  ${up("laptop")} {
    transform: translate(0, 0);
    transition: opacity ${timings.medium} ${cubicBezier} ${timings.quick},
      transform ${timings.medium} ${cubicBezier} ${timings.quick};
  }
`

const Container = styled.div<{ isOpen: boolean; extraWide: boolean }>`
  ${({ isOpen, extraWide }) => css`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    flex-direction: column;
    display: flex;
    opacity: 0;
    transform: translate(100%, 0);
    transition: opacity ${timings.medium} ${cubicBezier},
      transform ${timings.medium} ${cubicBezier};
    will-change: opacity, transform;
    width: 100%;
    height: 100%;
    background-color: ${colors.snow};
    cursor: default;

    form {
      &:focus {
        outline: none;
      }
    }

    ${up("laptop")} {
      top: ${columns(0.5)};
      left: 0;
      bottom: initial;
      transform: translate(0, 40%);
      width: ${columns(extraWide ? 12.5 : 8)};
      height: auto;
      max-height: 100%;
      margin: 0 auto;
    }

    ${isOpen && openProps}
  `}
`

export const ModalBody = styled.div<{
  extraWide: boolean
}>`
  ${({ extraWide }) => css`
    position: relative;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    padding: 0 20px;

    ${up("laptop")} {
      padding: ${extraWide ? `0 ${columns(0.5)}` : `0 ${columns(1)}`};
    }
  `}
`

interface ModalProps {
  isOpen: boolean
  mobileHeader?: string
  mobileClose?: boolean
  header?: string
  subheader?: string
  ctaComponent?: React.ReactNode
  headerComponent?: React.ReactNode
  hideHeaderOnMobile?: boolean
  hideHeaderOnDesktop?: boolean
  extraWide?: boolean
  children: React.ReactNode
  className?: string
  excludeRefs?: any
  backdropRef?: any
  onClose?: React.ReactEventHandler
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  mobileHeader,
  header,
  subheader,
  ctaComponent,
  headerComponent,
  hideHeaderOnMobile,
  hideHeaderOnDesktop,
  extraWide,
  children,
  className,
  excludeRefs,
  backdropRef,
  mobileClose = false,
  onClose,
}) => {
  const modalRef: any = React.useRef(null)

  useModalBackgroundClose({
    modalRef,
    excludeRefs,
    isOpen,
    onRequestClose: onClose,
  })

  return (
    <Backdrop isOpen={isOpen} ref={backdropRef}>
      <Container
        className={className}
        ref={modalRef}
        isOpen={isOpen}
        extraWide={!!extraWide}
      >
        {mobileHeader ||
        mobileClose ||
        header ||
        subheader ||
        headerComponent ||
        ctaComponent ? (
          <ModalHeader
            mobileHeader={mobileHeader}
            mobileClose={mobileClose}
            header={header}
            subheader={subheader}
            headerComponent={headerComponent}
            ctaComponent={ctaComponent}
            hideHeaderOnMobile={hideHeaderOnMobile}
            hideHeaderOnDesktop={hideHeaderOnDesktop}
            extraWide={extraWide}
            onClose={onClose}
          />
        ) : (
          ""
        )}
        <ModalBody extraWide={!!extraWide}>
          {children}
          <VerticalSpace
            heightString={extraWide ? columns(0.5) : columns(1)}
            mobileHeight={0}
          />
        </ModalBody>
      </Container>
    </Backdrop>
  )
}

export default Modal
