import React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"
import Link from "next/link"
import colors from "@constants/colors"
import { h5, h6, body } from "@constants/typography"
import Button from "@components/buttons/Button"
import VerticalSpace from "@components/VerticalSpace"

const Container = styled.div`
  overflow: hidden;
  overflow-y: auto;
  height: 100%;
  padding: 112px 20px 0;
  text-align: center;

  ${up("laptop")} {
    width: 100%;
    padding: 64px 0;
  }

  ${Button} {
    display: inline-flex;
    width: auto;
    padding: 0 24px;
  }
`

const Header = styled.h4`
  ${h5}

  ${up("laptop")} {
    ${h6}
  }
`

const Text = styled.div`
  ${body}
  text-align: center;
  color: ${colors.slateDark};
`

interface EmptyCartProps {
  showSignIn?: boolean
}

const EmptyCart: React.FC<EmptyCartProps> = ({ showSignIn }) => {
  return (
    <Container>
      <Header>Your cart is empty</Header>
      {showSignIn && (
        <>
          <VerticalSpace height={12} />
          <Text>Have an account? Sign in to see your cart.</Text>
          <VerticalSpace height={28} />
          <Link href="/login" passHref>
            <Button>Sign In</Button>
          </Link>
        </>
      )}
    </Container>
  )
}
export default EmptyCart
