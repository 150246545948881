import React from "react"
import styled from "styled-components"
import colors from "@constants/colors"
import { bodyBold } from "@constants/typography"
import { Close, Chevron } from "@components/icons"
import { Button, IconWrapper } from "@components/icons/utils"

const Container = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
  height: 88px;
  padding: 0 16px;
`

const Content = styled.div`
  ${bodyBold}
  align-items: center;
  display: flex;
  color: ${colors.obsidian};

  ${Button} {
    width: auto;
  }

  ${IconWrapper} {
    transform: rotate(90deg);
    margin-right: 8px;
  }
`

interface HeaderProps {
  label?: string
  onRequestBack?: () => void
  onRequestClose: () => void
}

const Header: React.FC<HeaderProps> = ({
  label,
  onRequestBack,
  onRequestClose,
}) => (
  <Container>
    <Content>
      {label && onRequestBack && (
        <Button onClick={onRequestBack}>
          <IconWrapper>
            <Chevron />
          </IconWrapper>
          {label}
        </Button>
      )}
    </Content>
    <Button onClick={onRequestClose}>
      <IconWrapper style={{ width: "30px", height: "30px" }}>
        <Close />
      </IconWrapper>
    </Button>
  </Container>
)

export default Header
