import React from "react"
import styled, { css } from "styled-components"
import { Image } from "react-datocms"
import Link from "next/link"
import colors from "@constants/colors"
import { timings, cubicBezier } from "@constants/animation"
import { bodyBold, h6, subtext } from "@constants/typography"
import { Close } from "@components/icons"
import { IconWrapper } from "@components/icons/utils"
import VerticalSpace from "@components/VerticalSpace"
import { DatoResponsiveImage } from "@dato/types"
import {
  getCarsMakeModelYearUrl,
  getCarsMakeModelYearTrimUrl,
} from "@utils/urls"
import _ from "lodash"
import { useRouter } from "next/router"
import SelectInput, {
  Container as SelectInputContainer,
} from "@components/inputs/SelectInput"
import { Info } from "@components/icons"

const baseButtonStyles = css`
  padding: 0;
  outline: none;
  border: none;
  background: none;

  &:focus,
  &:active {
    outline: none;
  }
`

const openProps = css`
  transform: translateX(0);
  transition: transform ${timings.medium} ${cubicBezier};
`
const Container = styled.div<{ isOpen?: boolean }>`
  ${({ isOpen }) => css`
    z-index: 3;
    position: absolute;
    top: 0;
    flex-direction: column;
    display: flex;
    opacity: 1;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    transform: translateX(100%);
    transition: transform ${timings.medium} ${cubicBezier};
    will-change: transform;
    width: 100%;
    height: 100%;
    background-color: ${colors.snow};
    cursor: default;

    ${isOpen ? openProps : ""}
  `}
`

const overlayActiveProps = css`
  opacity: 0.1;
  transition: opacity ${timings.medium} ${cubicBezier};
`
const Overlay = styled.div<{ isVisible: boolean }>`
  ${({ isVisible }) => css`
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity ${timings.long} ${cubicBezier};
    will-change: opacity;
    height: 100%;
    width: 100%;
    background-color: ${colors.black};
    pointer-events: none;

    ${isVisible ? overlayActiveProps : ""}
  `}
`

const CloseButton = styled.button`
  ${baseButtonStyles}
  align-items: center;
  justify-content: center;
  display: flex;
  width: 32px;
  height: 32px;
`

const Header = styled.div`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
`

const Body = styled.div`
  display: block;
  font-family: "Jost", sans-serif;
  padding: 30px 0;

  button {
    background: #ff1c24;
    color: #fff;
    min-width: 150px;
    text-transform: uppercase;
    border: 0;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: 0.6s ease-out;
    width: 100%;
    padding: 15px;

    &:hover {
      color: #ff1c24;
      background: #fff;
    }

    &:disabled {
      background: ${colors.mediumGray};
      cursor: not-allowed;
    }
  }
`

const Content = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px 40px;
`

const Filters = styled.div`
  display: block;
  width: 100%;
`

const Filter = styled.div<{ disabled?: boolean }>`
  min-width: 150px;
  font-family: "Jost", sans-serif;
  flex-grow: 0;
  margin-bottom: 10px;

  ${({ disabled }) => css`
    pointer-events: ${disabled ? "none" : "all"};

    [class*="SelectInput__Container"] {
      background-color: ${disabled ? colors.mediumGray : colors.lightestGray};
    }
  `}

  [class*="SelectInput__Container"] {
    padding: 0 8px;
    height: 50px;
  }

  [class*="MuiInput-formControl"] {
    margin-top: 22px;
    height: 16px;
    font-size: 14px;
  }

  [class*="MuiInputLabel-root"] {
    font-family: "Jost", sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 16px;
    transform: translate(0, 18px) scale(1);
    }
  }

  .MuiInputLabel-shrink {
    transform: translate(0, 4px) scale(0.6) !important;
  }

  .MuiNativeSelect-icon {
    top: 0px;
  }
`

const Label = styled.div`
  color: ${colors.obsidian};
  margin-bottom: 0;
  text-transform: uppercase;
  font-family: "Jost", sans-serif;
  text-align: center;

  > div {
    font-size: 20px;
    font-weight: 600;
  }

  > h1 {
    font-size: 25px;
    line-height: 25px;
    font-weight: 400;
    margin: 8px 0 16px 0;
  }
`

const Footer = styled.div`
  ${subtext}
  align-items: center;
  justify-content: center;
  display: flex;
  color: ${colors.slateDark};
  padding: 10px 40px;

  a {
    text-decoration: underline;
    color: ${colors.red};
    font-weight: 600;
  }

  ${IconWrapper} {
    opacity: 0.4;
    width: 20px;
    height: 20px;
    margin-right: 20px;
    border: 1.5px solid ${colors.slateLight};
    border-radius: 50%;
  }
`

interface CarSelectorProps {
  isOpen: boolean
  cars: Array<{
    make: {
      name: string
      slug: string
    }
    model: {
      name: string
      slug: string
      image: {
        responsiveImage: DatoResponsiveImage
      }
      imageWidth?: number
      imageHeight?: number
      mobileImageWidth?: number
      mobileImageHeight?: number
    }
    trim?: {
      name: string
      slug: string
      image: {
        responsiveImage: DatoResponsiveImage
      }
      imageWidth?: number
      imageHeight?: number
      mobileImageWidth?: number
      mobileImageHeight?: number
    }
    years: string
  }>
  makeModelOrder: Array<{
    make: { name: string; slug: string }
    models: Array<{ name: string; slug: string }>
  }>
  onRequestClose: () => void
  onRequestCloseMenu: () => void
}

const CarSelector: React.FC<CarSelectorProps> = ({
  isOpen,
  cars,
  makeModelOrder = {},
  onRequestClose,
  onRequestCloseMenu,
}) => {
  const router = useRouter()
  const layoutData = require("@data/layoutData")

  const [make, setMake] = React.useState<string | "">("")
  const [model, setModel] = React.useState<string | "">("")
  const [trim, setTrim] = React.useState<string | "">("")
  const [year, setYear] = React.useState<string | "">("")

  const setCar = (event) => {
    if (onRequestCloseMenu) {
      onRequestCloseMenu()
    }

    window.scrollTo({ top: 20, behavior: "smooth" })

    if (trim) {
      router.push(getCarsMakeModelYearTrimUrl(make, model, year, trim))
    } else {
      router.push(getCarsMakeModelYearUrl(make, model, year))
    }
  }

  const makes = _.sortBy(
    _.uniqBy(
      cars.map(({ make }) => ({ label: make.name, value: make.slug })).sort(),
      "value"
    ),
    ["value"]
  ) as Array<any>

  const [models, setModels] = React.useState<Array<any>>([])

  const [trims, setTrims] = React.useState<Array<any>>([])

  const [years, setYears] = React.useState<Array<any>>([])

  const onSelectMake = (make) => {
    const filteredModels = _.sortBy(
      _.uniqBy(
        cars
          .filter((car) => car.make.slug === make)
          .map(({ model }) => ({ label: model.name, value: model.slug })),
        "value"
      ),
      ["value"]
    )

    setMake(make)
    setModels(filteredModels)

    setMake(make)
    setModel("")
    setTrim("")
    setYear("")
    setTrims([])
    setYears([])

    document.getElementById("Model")?.focus()
  }

  const onSelectModel = (model) => {
    if (model.trim() != "") {
      const filteredCars = _.sortBy(
        cars.filter(
          (car) => car.make.slug === make && car.model.slug === model
        ),
        ["slug"]
      )

      if (filteredCars.length > 1) {
        const trims = _.sortBy(
          _.uniqBy(
            filteredCars.map(({ trim }) => ({
              label: trim?.name,
              value: trim?.slug,
            })) as any,
            "value"
          ),
          ["value"]
        ) as any

        setModel(model)
        setTrim("")
        setTrims(trims)
        setYears([])

        document.getElementById("Trim")?.focus()
      } else {
        const thisCar = filteredCars[0]
        const filteredYears = thisCar.years.split(", ").map((year) => ({
          label: year.trim(),
          value: year.trim(),
        }))

        setModel(model)
        setYear("")
        setTrim("")
        setTrims([])
        setYears(filteredYears)

        document.getElementById("Year")?.focus()
      }
    } else {
      setModel("")
      setTrims([])
      setYears([])
    }
  }

  const onSelectTrim = (trim) => {
    const thisCar = cars.find((car) => {
      return (
        car.make.slug === make &&
        car.model.slug === model &&
        car.trim?.slug === trim
      )
    })

    if (thisCar) {
      const filteredYears = thisCar.years.split(", ").map((year) => ({
        label: year.trim(),
        value: year.trim(),
      }))

      setTrim(trim)
      setYears(filteredYears)
    }
  }

  const reset = () => {
    setMake("")
    setModel("")
    setTrim("")

    setModels([])
    setTrims([])
    setYears([])
  }

  React.useEffect(() => {
    if (!isOpen) {
      reset()
    }
  }, [isOpen])

  return (
    <>
      <Overlay isVisible={isOpen} />
      <Container isOpen={isOpen}>
        <Header>
          <VerticalSpace height={30} />
          <CloseButton onClick={onRequestClose}>
            <IconWrapper>
              <Close />
            </IconWrapper>
          </CloseButton>
        </Header>
        <Body>
          <Label>
            <div>Shop by vehicle</div>
            <h1>Let's start that project</h1>
          </Label>
          <Content>
            <Filters>
              <Filter>
                <SelectInput
                  name="Make"
                  label="Make"
                  value={make}
                  options={makes}
                  onChange={(e) => {
                    onSelectMake(e.target.value)
                  }}
                />
              </Filter>
              <Filter disabled={!make}>
                <SelectInput
                  name={"Model"}
                  label={"Model"}
                  value={model}
                  options={models}
                  onChange={(e) => {
                    onSelectModel(e.target.value)
                  }}
                />
              </Filter>
              {trims?.length > 0 && (
                <Filter disabled={!make || !model}>
                  <SelectInput
                    name={"Trim"}
                    label={"Trim"}
                    value={trim}
                    options={trims}
                    onChange={(e) => {
                      years
                      onSelectTrim(e.target.value)
                    }}
                  />
                </Filter>
              )}
              <Filter
                disabled={
                  trims?.length > 0 ? !make || !model || !trim : !make || !model
                }
              >
                <SelectInput
                  name={"Year"}
                  label={"Year"}
                  value={year}
                  options={years}
                  onChange={(e) => {
                    setYear(e.target.value)
                  }}
                />
              </Filter>
              <button
                disabled={make == "" || model == "" || year == ""}
                onClick={(e) => {
                  setCar(e)
                }}
              >
                Build
              </button>
            </Filters>
          </Content>
        </Body>
        <VerticalSpace height={40} />
        <Footer>
          <IconWrapper>
            <Info />
          </IconWrapper>
          <span>
            Don't see your vehicle? Give us a call &nbsp;
            <a href="tel:1-888-460-0640">888-460-0640</a>
          </span>
        </Footer>
      </Container>
    </>
  )
}

export default CarSelector
