import React from "react"
import styled, { css } from "styled-components"
import Link from "next/link"
import colors from "@constants/colors"
import { timings, cubicBezier } from "@constants/animation"
import { body, h6 } from "@constants/typography"
import { Chevron } from "@components/icons"
import { IconWrapper } from "@components/icons/utils"

export const containerBaseProps = css`
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 100%;
  padding: 0 20px;
  border: none;
  border-bottom: solid 1px ${colors.divider};
  background-color: ${colors.snow};

  &:hover {
    transition: background-color ${timings.quick} ${cubicBezier};
    background-color: ${colors.divider};
    cursor: pointer;
  }

  &:focus,
  &:active {
    outline: none;
  }

  ${IconWrapper} {
    transform: rotate(-90deg);
  }
`

export const Button = styled.button<{ header?: boolean }>`
  ${({ header }) => css`
    ${containerBaseProps}
    height: ${header ? "56px" : "48px"};
  `}
`

export const Anchor = styled.a<{ header?: boolean }>`
  ${({ header }) => css`
    ${containerBaseProps}
    height: ${header ? "56px" : "48px"};
  `}
`

export const Label = styled.span<{ header?: boolean }>`
  ${({ header }) => css`
    ${header ? h6 : body}
    font-weight: ${header ? 500 : 400};
    color: ${colors.obsidian};
  `}
`

export interface ItemProps {
  header?: boolean
  label: string
  link?: string
  onClick?: () => void
}

const Item: React.FC<ItemProps> = ({ header, label, link, onClick }) => {
  if (link) {
    return (
      <Link href={link} passHref>
        <Anchor header={header} onClick={onClick}>
          <Label header={header}>{label}</Label>
          <IconWrapper>
            <Chevron strokeColor={colors.obsidian} />
          </IconWrapper>
        </Anchor>
      </Link>
    )
  }

  return (
    <Button onClick={onClick} header={header}>
      <Label header={header}>{label}</Label>
      <IconWrapper>
        <Chevron strokeColor={colors.obsidian} />
      </IconWrapper>
    </Button>
  )
}

export default Item
