import React from "react"
import styled, { css } from "styled-components"
import { up } from "styled-breakpoints"
import colors from "@constants/colors"
import { timings, cubicBezier } from "@constants/animation"
import Backdrop from "../Backdrop"
import Header from "./Header"
import Body from "./Body"
import Footer from "./Footer"
import EmptyCart from "./EmptyCart"
import useModalBackgroundClose from "@hooks/useModalBackgroundClose"
import { DiscountResponseProps, DiscountProps } from "./types"
import { CartItem } from "@redux/types"
import { MobileOnly, LaptopUpOnly } from "@utils/MediaQueries"
import { getLocalStorage, writeLocalStorage } from "@utils/storage"
import { DISCOUNT_KEY } from "@constants/storageKeys"

const openProps = css`
  opacity: 1;
  transform: translateX(0);
  transition: opacity ${timings.quick} ${cubicBezier},
    transform ${timings.medium} ${cubicBezier};
`

const Container = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  overflow-x: auto;
  width: 100%;
  padding-top: 88px;
  opacity: 0;
  transform: translateX(100%);
  transition: opacity ${timings.medium} ${cubicBezier},
    transform ${timings.quick} ${cubicBezier};
  will-change: opacity, transform;
  background-color: ${colors.snow};
  cursor: default;

  ${({ isOpen }) => (isOpen ? openProps : "")}

  ${up("laptop")} {
    max-width: 1080px;
    padding-top: 102px;
  }
`

const Content = styled.div`
  overflow: hidden;
  overflow-y: auto;
  height: 100%;

  ${up("laptop")} {
    align-items: flex-start;
    justify-content: flex-start;
    display: flex;
  }
`

export interface CartProps {
  showSignIn?: boolean
  isOpen: boolean
  items: Array<CartItem>
  subtotal: string
  total: string
  checkoutId: any
  cartCount: number
  checkoutLink: string
  onUpdateCartItem: (cartItem: CartItem) => void
  onUpdateDiscount: (discount: string | undefined) => DiscountResponseProps
  onRemoveDiscount: () => void
  onRequestClose: () => void
}

const Cart: React.FC<CartProps> = ({
  showSignIn,
  isOpen,
  checkoutId,
  items,
  subtotal,
  total,
  cartCount,
  checkoutLink,
  onUpdateCartItem,
  onUpdateDiscount,
  onRemoveDiscount,
  onRequestClose,
}) => {
  const cartRef: any = React.useRef(null)

  const onRemoveCartItem = (cartItem: CartItem): void => {
    onUpdateCartItem({
      ...cartItem,
      quantity: 0,
    })
  }

  const formattedItems = React.useMemo(() => {
    return items.map((item) => ({
      item,
      onUpdateCartItem,
      onRemoveCartItem,
    }))
  }, [items])

  const [discount, setDiscount] = React.useState<DiscountProps | undefined>(
    getLocalStorage(DISCOUNT_KEY)
  )

  const onAddDiscountSuccess = (discount: DiscountProps) => {
    setDiscount(discount)
    writeLocalStorage(DISCOUNT_KEY, discount)
  }

  const handleOnRemoveDiscount = () => {
    onRemoveDiscount()
    setDiscount(undefined)
    writeLocalStorage(DISCOUNT_KEY, undefined)
  }

  useModalBackgroundClose({
    modalRef: cartRef,
    isOpen,
    onRequestClose,
  })

  return (
    <Backdrop isOpen={isOpen}>
      <Container ref={cartRef} isOpen={isOpen}>
        <Header cartCount={cartCount} onRequestClose={onRequestClose} />
        {items.length ? (
          <Content>
            <Body items={formattedItems} />
            <Footer
              discount={discount}
              onAddDiscount={onUpdateDiscount}
              onAddDiscountSuccess={onAddDiscountSuccess}
              onRemoveDiscount={handleOnRemoveDiscount}
              checkoutId={checkoutId}
              items={items}
              subtotal={subtotal}
              total={total}
              checkoutLink={checkoutLink}
            />
          </Content>
        ) : (
          <>
            <MobileOnly>
              <EmptyCart showSignIn={showSignIn} />
            </MobileOnly>
            <LaptopUpOnly>
              <Content>
                <EmptyCart />
                <Footer
                  discount={discount}
                  onAddDiscount={onUpdateDiscount}
                  onAddDiscountSuccess={onAddDiscountSuccess}
                  onRemoveDiscount={handleOnRemoveDiscount}
                  checkoutId={checkoutId}
                  items={items}
                  subtotal={subtotal}
                  total={total}
                  checkoutLink={checkoutLink}
                />
              </Content>
            </LaptopUpOnly>
          </>
        )}
      </Container>
    </Backdrop>
  )
}

export default Cart
