import * as yup from "yup"
import { CustomerLogin } from "@redux/types"

export const initialValues: CustomerLogin = {
  email: "",
  password: "",
}

export default yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup.string().required("Password is required"),
})
