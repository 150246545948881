import React from "react"
import styled, { css } from "styled-components"
import { up } from "styled-breakpoints"
import Link from "next/link"
import colors from "@constants/colors"
import { timings, cubicBezier } from "@constants/animation"
import {
  DESKTOP_PROMO_HEIGHT,
  DESKTOP_HEADER_HEIGHT,
} from "@constants/measurements"
import { bodyBold, smallcaps } from "@constants/typography"
import Backdrop from "@components/Backdrop"
import SearchInput from "@components/inputs/SearchInput"
import CheckboxInput from "@components/inputs/CheckboxInput"
import VerticalSpace from "@components/VerticalSpace"
import useModalBackgroundClose from "@hooks/useModalBackgroundClose"
import {
  getCarsMakeModelYearUrl,
  getCategoryUrl,
  getSearchUrl,
} from "@utils/urls"

const ESCAPE_KEY = "Escape"
const ENTER_KEY = "Enter"

const openStyles = css`
  transform: translateY(0);

  ${up("laptop")} {
    transform: translateY(0);
  }
`
const Container = styled.div<{
  isOpen?: boolean
  xPosition?: string
  yPosition?: string
}>`
  ${({ isOpen, xPosition, yPosition }) => css`
    z-index: 1;
    position: relative;
    transform: translateY(-100%);
    transition: transform ${timings.quick} ${cubicBezier};
    background-color: ${colors.snow};

    ${up("laptop")} {
      position: absolute;
      top: ${yPosition
        ? yPosition
        : `calc(${DESKTOP_PROMO_HEIGHT} + ${DESKTOP_HEADER_HEIGHT})`};
      right: ${xPosition ? xPosition : "calc(42px + 290px - 66px)"};
      width: 380px;
      transform: translateY(10%);
    }

    ${isOpen && openStyles}
  `}
`

const SearchWrapper = styled.div`
  padding: 20px 0 20px 20px;
  border-bottom: solid 1px ${colors.divider};

  ${up("laptop")} {
    padding: 0;
    border: none;
  }
`

const CheckboxWrapper = styled.div`
  padding: 5px 0 5px 18px;
  border-bottom: solid 1px ${colors.divider};
`

const Suggestions = styled.div`
  padding: 32px 20px;

  ${up("laptop")} {
    padding: 38px 32px 32px;
  }
`

const Label = styled.div`
  ${smallcaps}
  margin-bottom: 18px;
  color: ${colors.slateDark};
`

const Text = styled.div`
  ${bodyBold}
  line-height: 32px;
  color: ${colors.slateDark};

  ${up("laptop")} {
    cursor: pointer;
  }
`

const TextBlack = styled.div`
  ${bodyBold}
  line-height: 32px;
  color: ${colors.obsidian};

  ${up("laptop")} {
    cursor: pointer;
  }
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.divider};
`

interface SearchProps {
  isOpen: boolean
  xPosition?: string
  yPosition?: string
  searchSuggestions: Array<{
    label: string
    searchString?: string
    make?: null | {
      id: string
      name: string
      slug: string
    }
    model?: null | {
      id: string
      name: string
      slug: string
    }
    year?: string
    category?: null | {
      id: string
      name: string
      slug: string
    }
  }>
  onSearch: (query: string, searchBySku?: boolean) => void
  onRequestClose: () => void
}

const Search: React.FC<SearchProps> = ({
  isOpen,
  xPosition,
  yPosition,
  searchSuggestions,
  onSearch,
  onRequestClose,
}) => {
  const [value, setValue] = React.useState<string>("")

  const onChange = (e) => {
    setValue(e.target.value)
  }

  const onKeyDown = (event) => {
    if (event.key === ESCAPE_KEY) {
      event.currentTarget.blur()
      onRequestClose && onRequestClose()
    } else if (event.key === ENTER_KEY && value.length > 1) {
      onSearch(value, searchBySku)
      onRequestClose && onRequestClose()
    }
  }

  const onClear = () => {
    setValue("")
  }

  const searchRef: any = React.useRef(null)
  const inputRef: any = React.useRef(null)

  useModalBackgroundClose({
    modalRef: searchRef,
    isOpen,
    onRequestClose,
  })

  React.useEffect(() => {
    if (!isOpen) {
      return
    } else if (inputRef?.current) {
      inputRef.current.focus()
    }
  }, [isOpen])

  const vehicleSuggestions = searchSuggestions
    .filter(({ make, model, year }) => !!make && !!model && !!year)
    .map(({ label, make, model, year }) => ({
      link: getCarsMakeModelYearUrl(make!.slug, model!.slug, year!),
      label,
    }))

  const popularSuggestions = searchSuggestions
    .filter(({ searchString }) => !!searchString)
    .map(({ label, searchString }) => ({
      link: getSearchUrl(searchString!),
      label,
    }))

  const categorySuggestions = searchSuggestions
    .filter(({ category }) => !!category)
    .map(({ label, category }) => ({
      link: getCategoryUrl(category!.slug),
      label,
    }))

  const [searchBySku, setSearchBySku] = React.useState<boolean>(false)

  const toggleSearchBySku = (e) => {
    setSearchBySku(!searchBySku)
  }

  return (
    <Backdrop isOpen={isOpen}>
      <Container
        ref={searchRef}
        isOpen={isOpen}
        xPosition={xPosition}
        yPosition={yPosition}
      >
        <SearchWrapper>
          <SearchInput
            inputRef={inputRef}
            value={value}
            placeholder="Search"
            onChange={onChange}
            onKeyDown={onKeyDown}
            onRequestClear={onClear}
          />
        </SearchWrapper>
        <Suggestions>
          {vehicleSuggestions?.length > 0 && (
            <>
              <Label>Vehicle</Label>
              {vehicleSuggestions.map(({ link, label }) => (
                <Link key={link} href={link} passHref>
                  <TextBlack>{label}</TextBlack>
                </Link>
              ))}
            </>
          )}
          {(vehicleSuggestions?.length > 0 ||
            popularSuggestions?.length > 0) && <VerticalSpace height={40} />}
          {popularSuggestions?.length > 0 && (
            <>
              <Label>Popular Suggestions</Label>
              {popularSuggestions.map(({ link, label }) => (
                <Link key={link} href={link} passHref>
                  <TextBlack>{label}</TextBlack>
                </Link>
              ))}
            </>
          )}
          {categorySuggestions?.length > 0 && (
            <>
              <VerticalSpace height={40} />
              <Divider />
              <VerticalSpace height={40} />
              <Label>Categories</Label>
              {categorySuggestions.map(({ link, label }) => (
                <Link key={link} href={link} passHref>
                  <Text>{label}</Text>
                </Link>
              ))}
            </>
          )}
        </Suggestions>
      </Container>
    </Backdrop>
  )
}

export default Search
