import styled, { css } from "styled-components"
import colors from "@constants/colors"
import Button from "../Button"

export default styled(Button)`
  ${({ disabled }) => css`
    background: ${colors.snow};
    border: 1px solid ${colors.divider};
    color: ${disabled ? colors.grayDisabled : colors.obsidian};
  `}
`
