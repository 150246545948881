import React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"
import Link from "next/link"
import colors from "@constants/colors"
import { body, bodyBold } from "@constants/typography"
import Button from "@components/buttons/Button"
import TextInput from "@components/inputs/TextInput"
import VerticalSpace from "@components/VerticalSpace"
import { formatCurrency } from "@utils/numbers"
import { DiscountResponseProps, DiscountProps } from "./types"
import useGtmEvents from "@hooks/useGtmEvents"

const ESCAPE_KEY = "Escape"
const ENTER_KEY = "Enter"

const Container = styled.div`
  padding: 32px 20px 110px;

  ${up("laptop")} {
    flex-shrink: 0;
    width: 420px;
    height: 100%;
    padding: 64px 0;
  }
`

const Content = styled.div`
  ${up("laptop")} {
    height: 100%;
    padding: 0 60px;
    border-left: solid 1px ${colors.divider};
  }
`

const Row = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
`

const Text = styled.div`
  ${body}
  color: ${colors.slateDark};
`

const TextBold = styled.div`
  ${bodyBold}
  color: ${colors.obsidian};
`

const InputWrapper = styled.div`
  position: relative;

  .MuiFormHelperText-root {
    margin-top: 8px !important;
  }
`

const InputButton = styled.button`
  ${bodyBold}
  position: absolute;
  top: 0;
  right: 0;
  height: 52px;
  margin: 0;
  padding: 0 20px;
  outline: none;
  border: none;
  background: none;
  color: ${colors.primary};

  &:focus,
  &:active {
    outline: none;
  }

  ${up("laptop")} {
    padding: 0 16px;

    &:hover {
      cursor: pointer;
    }
  }
`

const CheckoutButtonWrapper = styled.a`
  display: flex;
`

interface FooterProps {
  onAddDiscount: (discount: string | undefined) => DiscountResponseProps
  onAddDiscountSuccess: (discount: DiscountProps) => void
  onRemoveDiscount: () => void
  discount?: DiscountProps
  checkoutId: string | undefined
  items: Array<any> | []
  subtotal: string | undefined
  total: string | undefined
  checkoutLink: string | undefined
}

const Footer: React.FC<FooterProps> = ({
  onAddDiscount,
  onAddDiscountSuccess,
  onRemoveDiscount,
  discount,
  checkoutId,
  items,
  subtotal,
  total,
  checkoutLink,
}) => {
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(
    undefined
  )
  const [discountCode, setDiscountCode] = React.useState<string>("")

  const handleAddDiscount = async () => {
    if (!discountCode) {
      return
    }

    const response: DiscountResponseProps = await onAddDiscount(discountCode)

    if (response && response.code === "DISCOUNT_NOT_FOUND") {
      setDiscountCode("")
      setErrorMessage("Oops, that's not a valid code")
    } else if (response && response.message) {
      setDiscountCode("")
      setErrorMessage(response.message)
    } else {
      onAddDiscountSuccess({
        ...response,
        value: discountCode,
      })
      setDiscountCode("")
      setErrorMessage(undefined)
    }
  }

  const handleRemoveDiscount = () => {
    setErrorMessage(undefined)
    setDiscountCode("")
    onRemoveDiscount()
  }

  const onChange = ({ target: { value } }) => {
    setDiscountCode(value)
  }

  const onKeyDown = (event: any) => {
    if (event.key === ESCAPE_KEY) {
      event.currentTarget.blur()
      setDiscountCode("")
    }
    if (event.key === ENTER_KEY && event.currentTarget) {
      handleAddDiscount()
    }
  }

  const { onCheckout: onCheckoutGtmEvent } = useGtmEvents()

  const fireGTMcheckout = () => {
    onCheckoutGtmEvent(checkoutId, items, subtotal, total)
  }

  return (
    <Container>
      <Content>
        <Row>
          <Text>Subtotal</Text>
          <Text id="cartSubtotal">
            {subtotal
              ? formatCurrency(parseFloat(subtotal), "USD", "en-US")
              : "--"}
          </Text>
        </Row>
        <VerticalSpace mobileHeight={8} height={12} />
        {discount && (
          <>
            <Row>
              <Text>Discount</Text>
              <Text id="cartDiscount">
                {`- `}
                {subtotal && total
                  ? formatCurrency(
                      parseFloat(subtotal) - parseFloat(total),
                      "USD",
                      "en-US"
                    )
                  : "--"}
              </Text>
            </Row>
            <VerticalSpace mobileHeight={8} height={12} />
          </>
        )}
        <VerticalSpace mobileHeight={14} height={16} />
        <Row>
          <TextBold>Total</TextBold>
          <TextBold id="cartTotal">
            {total ? formatCurrency(parseFloat(total), "USD", "en-US") : "--"}
          </TextBold>
        </Row>
        <VerticalSpace mobileHeight={30} height={36} />
        <InputWrapper>
          <TextInput
            type="text"
            label="Discount Code"
            placeholder="Discount Code"
            value={discountCode}
            onChange={onChange}
            onKeyDown={onKeyDown}
            error={!!errorMessage}
            helperText={errorMessage}
          />
          {discount ? (
            <InputButton onClick={handleRemoveDiscount}>Clear</InputButton>
          ) : (
            <InputButton onClick={handleAddDiscount}>Apply</InputButton>
          )}
        </InputWrapper>
        <VerticalSpace height={16} />
        {checkoutLink ? (
          <div onClick={fireGTMcheckout}>
            <Link href={checkoutLink} passHref>
              <CheckoutButtonWrapper>
                <Button id="cartCheckout">Checkout</Button>
              </CheckoutButtonWrapper>
            </Link>
          </div>
        ) : (
          <CheckoutButtonWrapper>
            <Button disabled>Checkout</Button>
          </CheckoutButtonWrapper>
        )}
      </Content>
    </Container>
  )
}

export default Footer
