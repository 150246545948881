export const hookOnChange = (field, onChange) => (...args: any) => {
  const changeResult = field.onChange(...args)
  onChange(...args)
  return changeResult
}

export const hookOnChangeCustomInput = (form, fieldName, onChange) => (
  ...args: any
) => {
  const changeResult = form.setFieldValue(fieldName, ...args)
  onChange(...args)
  return changeResult
}
