import React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"
import colors from "@constants/colors"
import { bodyBold, h6 } from "@constants/typography"
import { Close } from "@components/icons"

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  display: flex;
  height: 88px;
  padding: 0 20px;
  border-bottom: 1px solid ${colors.divider};

  ${up("laptop")} {
    height: 102px;
    padding: 0 60px;
  }
`

const Text = styled.div`
  ${bodyBold}

  ${up("laptop")} {
    ${h6}
  }
`

const CloseButton = styled.button`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: flex;
  transform: translateX(10px);
  width: 44px;
  height: 44px;
  outline: none;
  border: none;
  background: none;

  &:focus,
  &:active {
    outline: none;
  }

  ${up("laptop")} {
    transform: translateX(-6px);
    border-radius: 2px;
    background-color: ${colors.divider};

    &:hover {
      cursor: pointer;
    }
  }
`

interface HeaderProps {
  cartCount: number
  onRequestClose: () => void
}

const Header: React.FC<HeaderProps> = ({ cartCount, onRequestClose }) => {
  return (
    <Container>
      <Text>{`Cart (${cartCount})`}</Text>
      <CloseButton onClick={onRequestClose}>
        <Close />
      </CloseButton>
    </Container>
  )
}

export default Header
